import { env } from "@/env";

// constant
export const gridSpacing = 3;
export const drawerWidth = 260;
export const drawerWidthClosed = 98;
export const appDrawerWidth = 320;
export const maxScroll = 100000;
export const SERVER_URL = env.REACT_APP_SERVER_URL;
export const baseURL = SERVER_URL;
export const uiBaseURL = window.location.origin;
export const FLOWISE_CREDENTIAL_ID = 'FLOWISE_CREDENTIAL_ID';
export const REDACTED_CREDENTIAL_VALUE =
  '_FLOWISE_BLANK_07167752-1a71-43b1-bf8f-4f32252165db';
export const PUBLIC_URL = window.location.origin;
