import { Button } from '@mui/material'
import React from 'react'
import Illustration, { IllustrationsCodes } from '../illustrations/Illustration'
import { IconReload } from '@tabler/icons'

type ErrorContentProps = {
  onRetry: () => void
}
export default function ErrorContent(props: ErrorContentProps) {
  return (
    <Illustration
      code={IllustrationsCodes.error}
      title='Something went wrong'
      subtitle='Our team is already aware of the situation'
      content={(
        <Button
          startIcon={<IconReload />}
          variant='contained'
          color='primary'
          onClick={props.onRetry}
        >
          Retry
        </Button>
      )}
    />
  )
}
