import React, { ReactNode, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

type SectionProps = {
  code: string,
  children?: ReactNode;
};

export function SectionInjector({ code, children }: SectionProps) {
  return <div data-section-code={code} />
}

export function Section({ code, children }: SectionProps) {
  const selector = `[data-section-code=${code}]`

  const elRef = useRef<HTMLDivElement | null>(null);
  if (!elRef.current) elRef.current = document.createElement("div");

  useEffect(() => {
    const el = elRef.current!; // non-null assertion because it will never be null
    const sectionRoot = document.querySelector(selector);
    if (sectionRoot) sectionRoot.appendChild(el);
    return () => {
      if (sectionRoot) sectionRoot.removeChild(el);
    };
  }, []);
  return createPortal(children, elRef.current);
}