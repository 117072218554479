// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ``, "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paper": `#fcfcfd`,
	"primaryLight": `#e3f2fd`,
	"primary200": `#90caf9`,
	"primaryMain": `#90caf9`,
	"primaryDark": `#1e88e5`,
	"primary800": `#1565c0`,
	"secondaryLight": `#ede7f6`,
	"secondary200": `#b39ddb`,
	"secondaryMain": `#673ab7`,
	"secondaryDark": `#5e35b1`,
	"secondary800": `#4527a0`,
	"successLight": `#cdf5d8`,
	"success200": `#37996b`,
	"successMain": `#37996b`,
	"successDark": `rgba(55,153,107,.8)`,
	"successDarker": `rgba(43,120,84,.8)`,
	"errorLight": `#f3d2d2`,
	"errorMain": `#e5484d`,
	"errorDark": `#c62828`,
	"orangeLight": `#fbe9e7`,
	"orangeMain": `#ffab91`,
	"orangeDark": `#d84315`,
	"tealLight": `#76c893`,
	"tealMain": `#52b69a`,
	"tealDark": `#34a0a4`,
	"warningLight": `#fff8e1`,
	"warningMain": `#ffe57f`,
	"warningDark": `#ffc107`,
	"grey20": `#ededed`,
	"grey50": `#fafafa`,
	"grey100": `#f5f5f5`,
	"grey200": `#eee`,
	"grey300": `#e0e0e0`,
	"grey500": `#9e9e9e`,
	"grey600": `#757575`,
	"grey700": `#616161`,
	"grey800": `#323232`,
	"grey900": `#212121`,
	"darkPaper": `#232323`,
	"darkBackground": `#1c1c1c`,
	"darkLevel1": `#1c1c1c`,
	"darkLevel2": `#1c1c1c`,
	"darkTextTitle": `#d7dcec`,
	"darkTextPrimary": `#bdc8f0`,
	"darkTextSecondary": `#8492c4`,
	"darkPrimaryLight": `#1c1c1c`,
	"darkPrimaryMain": `#23262c`,
	"darkPrimaryDark": `#232323`,
	"darkPrimary200": `#c9d4e9`,
	"darkPrimary400": `#6e737d`,
	"darkPrimary800": `#2e2e2e`,
	"darkSecondaryLight": `#454c59`,
	"darkSecondaryMain": `#754aee`,
	"darkSecondaryDark": `#643fcb`,
	"darkSecondary200": `#32353b`,
	"darkSecondary800": `#6200ea`,
	"transparent": `rgba(255,255,255,0)`
};
export default ___CSS_LOADER_EXPORT___;
