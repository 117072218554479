import React, { lazy } from 'react'
import MinimalLayout from '@/layout/MinimalLayout'

const Canvas = lazy(() => import('@/views/canvas'))
const MarketplaceCanvas = lazy(() => import('@/views/marketplaces/MarketplaceCanvas'))

const CanvasRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/canvas/:id',
            element: <Canvas />
        },
        {
            path: '/marketplace/:id',
            element: <MarketplaceCanvas />
        }
    ]
}

export default CanvasRoutes
