import { PolymorphicPropsWithChildren } from '@/typings';
import { Box, Stack } from '@mui/material';
import React, { ElementType } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

type DrawerLayerContentProps = {
  disablePadding?: boolean,
  disableScroll?: boolean,
}
export default function DrawerLayerContent<C extends ElementType>({ children, disablePadding = false, disableScroll = false, as, ...rest }: DrawerLayerContentProps & PolymorphicPropsWithChildren<C>) {
  const Component = as || 'div';
  const ScrollComponent = disableScroll ? 'div' : PerfectScrollbar;

  return (
    <Box
      component={ScrollComponent}
      flexGrow={1}
      sx={{
        height: '100%',
        overflowX: 'hidden',
        overflowY: disableScroll ? 'hidden' : 'auto',
        bgcolor: 'background.default'
      }}
    >
      <Stack
        component={Component}
        {...rest}
        display='flex'
        flexGrow={1}
        gap={2}
        sx={{
          p: !disablePadding && 2,
          height: '100%'
        }}
      >
        {children}
      </Stack>
    </Box>
  )
}