import {
  SignedOut,
  SignInButton,
  UserButton,
  SignedIn,
} from '@clerk/clerk-react';
import { Button, Box } from '@mui/material';
import React from 'react';

export default function Component({ showName }: { showName: boolean }) {
  return (
    <Box className='profile-button' component='div'>
      <SignedOut>
        <SignInButton>
          <Button variant="outlined">Login</Button>
        </SignInButton>
      </SignedOut>
      <SignedIn>
        <UserButton
          afterSignOutUrl="https://www.gaiahub.ai"
          showName={showName}
        />
      </SignedIn>
    </Box>
  );
}
