import { SxProps, TextField } from '@mui/material';
import React from 'react';
import { PropsWithFormItem } from './form-item.types';

type FormItemTextFieldProps = {
  required?: boolean;
  multiline?: boolean;
  rows?: number;
  helperText?: string;
  placeholder?: string;
  value?: string;
  disabled?: boolean;
  defaultValue?: string
  sx?: SxProps;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}
export default function FormItem({
  required = false,
  label,
  name,
  multiline = false,
  rows = 5,
  helperText,
  defaultValue,
  value,
  sx,
  placeholder,
  disabled = false,
  onChange
}: PropsWithFormItem<FormItemTextFieldProps>) {
  return (
    <TextField
      required={required}
      id={`form-item-${name}`}
      sx={{
        resize:'vertical',
        ...sx,
      }}
      InputProps={{
        sx:{
          resize:'vertical'
        }
      }}
      variant='outlined'
      label={label}
      placeholder={placeholder}
      disabled={disabled}
      multiline={multiline}
      rows={!!multiline ? rows : 0}
      helperText={helperText}
      onChange={onChange}
      value={value}
      defaultValue={defaultValue}
    />
  )
}
