import { useMediaQuery, useTheme } from "@mui/material";

export function useVisibility() {
  const theme = useTheme();
  const isUpXs = useMediaQuery(theme.breakpoints.up('xs'));
  const isUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const isUpLg = useMediaQuery(theme.breakpoints.up('lg'));
  const isUpXl = useMediaQuery(theme.breakpoints.up('xl'));

  return {
    isUpXs,
    isUpSm,
    isUpMd,
    isUpLg,
    isUpXl
  }
}