import { env } from "@/env";

const environment = env.REACT_APP_GAIA_ENV;
const _newrelic = {
  _instance: window.newrelic || null,
  get instance() {
    if (!this._instance) {
      this._instance = window.newrelic;
    }
    return this._instance;
  }
}

const newrelicInstance = {
  setCustomAttribute(attr: string, value: any) {
    _newrelic._instance?.setCustomAttribute(attr, value);
  },
  noticeError(error){
    _newrelic._instance?.noticeError(error);
  },
  setUserId(userId: string) {
    _newrelic._instance?.setUserId(userId)
  }
}

function initNewRelic() {
  newrelicInstance.setCustomAttribute('env', environment);
}

export { initNewRelic }
export default newrelicInstance;