import { ApiDataResult } from "@/typings";
import { BaseModel } from "./BaseModel";

export class ChatFlowTemplate extends BaseModel {
  id: string
  name: string;
  description: string;
  nodes: any;
  edges: any;

  static load(data: ApiDataResult) {
    const model = new ChatFlowTemplate();
    model.id = data['id'];
    model.name = data['name'];
    model.description = data['description'];
    model.nodes = data['nodes'];
    model.edges = data['edges'];
    return model;
  }

  toJSON() {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      nodes: this.nodes,
      edges: this.edges,
    }
  }
}