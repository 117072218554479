import { ClerkProvider, RedirectToSignIn, SignedIn, SignedOut } from '@clerk/clerk-react';
import '@/assets/scss/style.scss';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { createRoot, } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { ErrorBoundary } from './components';
import { store } from './store';
import ConfirmContextProvider from './store/context/ConfirmContextProvider';
import { ReactFlowContext } from './store/context/ReactFlowContext';
import { initNewRelic } from './utils/newrelic';
import { env } from './env';
import './i18n';

const container = document.getElementById('root');
const root = createRoot(container);
const PUBLISHABLE_KEY = env.REACT_APP_CLARK_PUB_KEY;

if (!PUBLISHABLE_KEY) {
  throw new Error('PUBLISHABLE_KEY not defined');
}

initNewRelic()
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <ClerkProvider publishableKey={PUBLISHABLE_KEY}>
        <SignedIn>
          <Provider store={store}>
            <BrowserRouter>
              <SnackbarProvider>
                <ConfirmContextProvider>
                  <ReactFlowContext>
                    <App />
                  </ReactFlowContext>
                </ConfirmContextProvider>
              </SnackbarProvider>
            </BrowserRouter>
          </Provider>
        </SignedIn>
        <SignedOut>
          <RedirectToSignIn />
        </SignedOut>
      </ClerkProvider>
    </ErrorBoundary>
  </React.StrictMode>
);
