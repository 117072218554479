import client from "@/api/client";
import AppSettings from "@/config/main";
import { Assistant } from "@/models/Assistant";
import queryClient from "@/utils/query-client";
import { delayMs } from "@/utils/timer";
import { useMutation, useQuery } from "react-query";

export enum AssistantsRequests {
  all = 'assistants.all',
  pagination = 'assistants.pagination',
  detail = 'assistants.detail'
}

export const useLoadAllAssistants = () => {
  return useQuery<Assistant[], Error>(AssistantsRequests.all, async () => {
    const { data } = await client.get("/assistants");
    await delayMs(AppSettings.defaultRequestDelay);
    return data.map(Assistant.load)
  });
}

export const useLoadAssistantDetail = (id: string) => {
  return useQuery<Assistant, Error>(AssistantsRequests.detail, async () => {
    const { data } = await client.get(`/assistants/${id}`);
    await delayMs(AppSettings.defaultRequestDelay);
    return Assistant.load(data)
  });
}

export const useCreateAssistantMutation = () => {
  return useMutation({
    async mutationFn(model: Assistant) {
      const data = model.toFormData();
      const result = await client.post('/assistants', data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return result.data
    },
    onSuccess() {
      queryClient.invalidateQueries(AssistantsRequests.all)
    },
  });
}

export const useUpdateAssistantMutation = () => {
  return useMutation({
    async mutationFn(model: Assistant) {
      const formData = model.toFormData();
      const result = await client.put(`/assistants/${model.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return result.data
    },
    onSuccess() {
      queryClient.invalidateQueries(AssistantsRequests.all)
    }
  });
}

export const useDeleteAssistantMutation = () => {
  return useMutation({
    async mutationFn(id: string) {
      const result = await client.delete(`/assistants/${id}`);
      return result
    },
    onSuccess() {
      queryClient.invalidateQueries(AssistantsRequests.all)
    }
  });
}