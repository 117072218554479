import { PolymorphicProps, RequiredPolymorphicProps } from '@/typings';
import { Box, Typography } from '@mui/material';
import React, { ElementType, useMemo } from 'react';
import { useFormContext, CustomElement, FieldElement, FieldName, useForm, useWatch } from 'react-hook-form-mui';

type FormCustomElementProps = {
  name: string
  label?: string;
  required?: boolean,
  helperText?: React.ReactNode
};
export default function FormCustomElement<T extends ElementType>(props: PolymorphicProps<T> & FormCustomElementProps) {
  const { name, required, as, label, helperText, ...rest } = props;
  const { getValues, clearErrors, setValue, register, formState: { errors } } = useFormContext();
  register(name, { required });

  const error = errors[name] || null;
  const value = getValues(name);

  const handleOnChange = (value: any) => {
    clearErrors(name);
    setValue(name, value);
  }

  const Component = as || 'div';
  return (
    <Box>
      {!!label && (
        <Typography variant='h4' mb={1}>
          {label}
        </Typography>
      )}
      <Component {...rest} onChange={handleOnChange} value={value} />
      {!!error && (
        <Typography variant='body1' color='error'>
          This field is required
        </Typography>
      )}
      {!!helperText && (
        <>{helperText}</>
      )}
    </Box>
  )
}
