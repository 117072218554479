import { getFileAsString } from '@/utils/file';
import { UploadFile } from '@mui/icons-material';
import { Stack, Typography, Box, Avatar, Button } from '@mui/material';
import React, { useEffect, useState } from 'react'
import imagePlaceholder from '@/assets/images/default-image.svg';
import { faker } from '@faker-js/faker';

type IconPickerProps = {
  defaultImage?: string;
  value?: string;
  label?: string;
  showButton?: boolean,
  resultType?: 'file' | 'string';
  onChange?: (value: string | File) => void
}

export default function IconPicker(props: IconPickerProps) {
  const { label, value, onChange, showButton = false, resultType = 'file', defaultImage} = props;
  const [image, setImage] = useState<string>(defaultImage);

  const handleUploadIconClick = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/jpeg, image/png, image/svg+xml';
    fileInput.addEventListener('change', async (e: Event) => {
      const files = (e.target as HTMLInputElement).files;
      const file = files.length > 0 ? files.item(0) : null;
      if (!!file) {
        const imageString = await getFileAsString(file);
        const result = resultType === 'file' ? file : imageString;
        setImage(imageString);
        !!onChange && onChange(result);
      }
    });
    fileInput.click();
  }

  return (
    <Stack direction='column' gap={2} >
      {!!label && (
        <Typography variant='h4'>
          {label}
        </Typography>
      )}
      <Stack direction='row' gap={2}>
        <Box component='div' className='form-item-icon-upload-container' onClick={handleUploadIconClick}>
          <Avatar
            sx={{ width: 48, height: 48 }}
            src={image || imagePlaceholder}
          />
          <Box component='div' className='form-item-icon-upload-overlay'> <UploadFile /> </Box>
        </Box>
        {
          showButton && (
            <Button
              variant='outlined'
              color='primary'
              size='small'
              startIcon={<UploadFile />}
              onClick={handleUploadIconClick}
            >
              Upload icon
            </Button>
          )
        }
      </Stack>
    </Stack>
  )
}
