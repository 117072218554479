import { TypographyOptions } from "@mui/material/styles/createTypography";
import { Colors } from "./theme.typings";
import { ExtendedTypographyOptions } from "@/typings";

const thinFont = 'GeistThin';
const lightFont = 'GeistLight';
const regularFont = 'GeistRegular';
const boldFont = 'GeistBold';

export default function themeTypography(colors: Colors): TypographyOptions {
    const options = {
        fontFamily: regularFont,
        h6: {
            fontFamily: regularFont,
            color: colors.headingTextColor2,
            fontSize: '0.75rem'
        },
        h5: {
            fontFamily: regularFont,
            color: colors.headingTextColor2,
            fontSize: '0.875rem',
        },
        h4: {
            fontFamily: regularFont,
            color: colors.headingTextColor2,
            fontSize: '1rem',
        },
        h3: {
            fontFamily: boldFont,
            fontSize: '1.25rem',
            color: colors.headingTextColor,
        },
        h2: {
            fontFamily: boldFont,
            fontSize: '1.5rem',
            color: colors.headingTextColor,
        },
        h1: {
            fontFamily: boldFont,
            fontSize: '2.125rem',
            color: colors.headingTextColor,
        },
        subtitle1: {
            fontFamily: regularFont,
            fontSize: '0.875rem',
            color: colors.subtitle1
        },
        subtitle2: {
            fontFamily: regularFont,
            fontSize: '0.75rem',
            color: colors.subtitle2
        },
        caption: {
            fontFamily: lightFont,
            fontSize: '0.75rem',
            color: colors.caption,
        },
        body1: {
            fontFamily: lightFont,
            fontSize: '0.875rem',
            color: colors.body1,
            lineHeight: '1.334em'
        },
        body2: {
            fontFamily: regularFont,
            letterSpacing: '0em',
            color: colors.body2
        },
        button: {
            fontFamily: boldFont,
            textTransform: 'capitalize',
            color: colors.button
        },
        title: {
            fontFamily: boldFont,
            color: colors.headingTextColor,
            fontSize: '1.875rem',
            lineHeight: '2.25rem'
        },
        label: {
            fontFamily: regularFont,
            color: colors.subtitle2,
            fontSize: '.875rem',
            lineHeight: '1.25rem'
        },
        labelValue: {
            fontFamily: boldFont,
            color: colors.subtitle2,
            fontSize: '1rem',
            lineHeight: '1.75rem',
            fontWeight: 400
        }
    }
    return options as ExtendedTypographyOptions;
}