import { Avatar, Button, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import iconEn from './icons/england.svg';
import iconFr from './icons/france.svg';
import iconPt from './icons/portugal.svg';
import iconEs from './icons/spain.svg';

const languages = [
  {
    code: 'en',
    icon: iconEn,
    label: 'English'
  },
  {
    code: 'es',
    icon: iconEs,
    label: 'Spanish'
  },
  {
    code: 'pt',
    icon: iconPt,
    label: 'Português'
  },
  {
    code: 'fr',
    icon: iconFr,
    label: 'French'
  },
]

export default function LanguageSelector() {
  const { t, i18n } = useTranslation();
  const [menuAnchor, setMenuAnchor] = useState(null);
  const open = Boolean(menuAnchor)

  const selectedLng = useMemo(() => languages.find(l => l.code === i18n.language), [i18n.language])

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchor(event.currentTarget);
  }

  const handleOnChangeLanguage = (newLng: string) => {
    i18n.changeLanguage(newLng);
    setMenuAnchor(null);
  }

  return (
    <>
      <Button
        endIcon={
          <Avatar
            src={selectedLng.icon}
            sx={{
              width: 18,
              height: 18
            }}
          />
        }
        variant='outlined'
        size='small'
        color='secondary'
        property='action'
        onClick={handleClick}
      >
        {t('common:app_language')}
      </Button>
      <Menu
        open={open}
        anchorEl={menuAnchor}
        onClose={() => setMenuAnchor(null)}
      >
        {
          languages.map(language => (
            <MenuItem key={`lng-${language.code}`} disabled={language.code === i18n.language} value={language.code} onClick={() => handleOnChangeLanguage(language.code)}>
              <ListItemIcon>
                <Avatar
                  src={language.icon}
                  sx={{
                    width: 18,
                    height: 18
                  }}
                />
              </ListItemIcon>
              <ListItemText>
                {language.label}
              </ListItemText>
            </MenuItem>
          ))
        }
      </Menu>
    </>
  )
}
