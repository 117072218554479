import axios from 'axios';
import { SERVER_URL } from '@/store/constant';


const apiClient = axios.create({
  withCredentials: true,
  baseURL: `${SERVER_URL}/api/v1`,
  headers: {
    'Content-type': 'application/json',
  },
});

apiClient.interceptors.request.use(async function (config) {
  
  try {
    const token = await Clerk.session.getToken();
    config.headers.Authorization = `Bearer ${token}`    
  } catch (error) {
    console.log(`Error trying to get the token: ${error}`)
  }

  return config;
});

export default apiClient;
