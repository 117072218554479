import { ReactNode } from "react";
import { DataTableCallbackParam, DataTableColumnConfig, DataTableRowCellProps, DataTableVisibleColumnMap } from "./data-table-typings";
import moment from "moment";
import { SxProps } from "@mui/material";
import { getDeepValueFromPath } from "@/utils/json";

export function getRowCellContent<T>(config: DataTableColumnConfig<T>, param): String | ReactNode {
  const {
    field,
    type = 'text',

    noValue = '-',
    booleanRepresentation,
    dateFormat = 'D/MM/Y',
    numberFormat,

    renderCell,
    valueGetter
  } = config;

  if (!!renderCell)
    return renderCell(param);

  if (!!valueGetter)
    return valueGetter(param);

  let value: any;
  if (field.includes('.')) {
    value = getDeepValueFromPath(param.row, field);
  } else {
    value = param.row[field]
  }

  if (type !== 'boolean' && !value) {
    return noValue;
  }

  try {
    switch (type) {
      case 'text':
        return (value || '').toString();
      case 'boolean':
        if (!!booleanRepresentation) {
          const { ok, not } = booleanRepresentation;
          return !!value ? ok : not;
        }
        else {
          return !!value ? 'true' : 'false'
        }
      case 'date':
        if (!!dateFormat) {
          return moment(value).format(dateFormat)
        }
        else {
          return (value as Date).toLocaleDateString();
        }
      case 'number':
        if (!!numberFormat) {
          return new Intl.NumberFormat('en-US', { maximumSignificantDigits: 3 }).format(
            value,
          );
        }
        else {
          return (value as number).toString();
        }
      default:
        return value;
    }
  } catch (error) {
    console.warn('Error on field:' + field + ' type:' + type)
    console.warn(error);
    return value;
  }
}

export function getCellPropsFromConfig<T>(config: DataTableColumnConfig<T>): SxProps {
  const isActionsCell = config.field === 'actions' || config.type === 'actions';
  return {
    flexGrow: config.flex,
    width: config.width,
    minWidth: config.minWidth,
    maxWidth: config.maxWidth,
    justifyContent: isActionsCell ? 'flex-end' : config.align,
    textAlign: isActionsCell ? 'right' : config.align
  }
}

export function renderRowCell<T>(rowData: { data: any, rowIndex: number }, columnConfig: DataTableColumnConfig<T>, columnIndex: number): { props: any, value: any } {
  const { data, rowIndex } = rowData;
  const param: DataTableCallbackParam<T> = {
    row: data
  }
  const value = getRowCellContent(columnConfig, param);
  const props: DataTableRowCellProps = {
    key: `table-body-row-${rowIndex}-cell-${columnIndex}`,
    sx: getCellPropsFromConfig(columnConfig)
  }
  return {
    props,
    value
  };
}

export function filterOnlyVisibleColumns<T>(config: DataTableColumnConfig<T>, visibleMap: DataTableVisibleColumnMap): boolean {
  if (!config.hiddenOn) {
    return true;
  }
  const isVisible = visibleMap[config.hiddenOn];
  return isVisible;
}