import { ChatbotMessage, MessageType } from '@/typings';
import React from 'react'

type UsedToolsProps = {
  tools: Pick<ChatbotMessage, "usedTools">
};

export default function UsedTools(props: UsedToolsProps) {
  const { tools } = props;

  if (!tools) {
    return null;
  }

  return (
    <div>UsedTools</div>
  )
}
