import React, { lazy } from 'react'
import MainLayout from '@/layout/MainLayout'

const Chatflows = lazy(() => import('@/views/chatflows'))
const Marketplaces = lazy(() => import('@/views/marketplaces'))
const APIKey = lazy(() => import('@/views/apikey'))
const Tools = lazy(() => import('@/views/tools'))
const Credentials = lazy(() => import('@/views/credentials'))
const Variables = lazy(() => import('@/views/variables'))
const Subscription = lazy(() => import('@/views/subscription'))
const Assistants = lazy(() => import('@/views/assistants'))

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <Chatflows />
        },
        {
            path: '/chatflows',
            element: <Chatflows />
        },
        {
            path: '/marketplaces',
            element: <Marketplaces />
        },
        {
            path: '/apikey',
            element: <APIKey />
        },
        {
            path: '/tools',
            element: <Tools />
        },
        {
            path: '/assistants',
            element: <Assistants />
        },
        {
            path: '/credentials',
            element: <Credentials />
        },
        {
            path: '/variables',
            element: <Variables />
        },
        {
            path: '/subscription',
            element: <Subscription />
        },
    ]
}

export default MainRoutes
