import { Add, Delete } from '@mui/icons-material'
import { Box, Button, IconButton, Stack, TextField, Typography } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'

export type KeyValueItem = {
  keyItem: string,
  valueItem: string,
}

type KeyValueProps = {
  label?: string;
  value?: KeyValueItem[] | Record<string, string>[],
  rowsFormatter?: (value: any) => KeyValueItem[];
  resultFormatter?: (data: KeyValueItem[]) => any;
  onChange?: (rows: KeyValueItem[]) => void
}

export default function KeyValue({ value, onChange, rowsFormatter, resultFormatter, label }: KeyValueProps) {
  const [data, setData] = useState<KeyValueItem[]>([]);

  useEffect(() => {
    const _data = !!rowsFormatter ? rowsFormatter((value || [])) : (value || []) as KeyValueItem[];
    setData(_data);
  }, [value]);

  const handleAddNewClick = () => {
    setData([
      ...data,
      {
        keyItem: '',
        valueItem: ''
      }
    ]);
  }

  const notifyChange = useCallback((_data) => {
    const result = !!resultFormatter ? resultFormatter(_data) : _data;
    !!onChange && onChange(result);
  }, []);

  const handleOnInputBlur = (e) => {
    const { name, value } = e.target;
    const [, propName, itemIndex] = name.split('-');

    if (!value || typeof value === 'undefined' || value.length === 0) {
      return;
    }
    const nIndex = parseInt(itemIndex);
    const isValueItem = propName === 'valueItem';
    const hasKeyValue = data[nIndex].keyItem.length > 0;

    const _data = data.map((d, i) => ({
      ...d,
      [propName]: i === nIndex ? value : d[propName]
    }));

    const shouldNotifyChange = (isValueItem && hasKeyValue)
    setData(_data);
    if (shouldNotifyChange) {
      notifyChange(_data);
    }
  }

  const handleOnRemoveItem = (index, _e) => {
    data.splice(index, 1);
    const newData = [
      ...data
    ];
    setData(newData);
    notifyChange(newData)
  }

  return (
    <Box component='div' className='key-value-container'>
      {!!label && (
        <Typography variant='h4' mb={1}>
          {label}
        </Typography>
      )}
      {
        data.length > 0 && (
          <Stack direction='column' component='div' flexGrow={1} gap={2} mb={2}>
            {
              data.map((item, index) => (
                <Box key={`keyvalue_item-${index}`} component='div' display='flex' gap={2} >
                  <TextField
                    variant='outlined'
                    size='small'
                    sx={{ flex: 4 }}
                    onBlur={handleOnInputBlur}
                    name={`keyvalue_item-keyItem-${index}`}
                    placeholder='Key'
                    defaultValue={item.keyItem}
                  />
                  <TextField
                    variant='outlined'
                    size='small'
                    sx={{ flex: 8 }}
                    onBlur={handleOnInputBlur}
                    name={`keyvalue_item-valueItem-${index}`}
                    placeholder='Value'
                    defaultValue={item.valueItem}
                  />
                  <Box sx={{ flex: 1 }}>
                    <IconButton color='secondary' property='delete' size='small' onClick={handleOnRemoveItem.bind(null, index)}>
                      <Delete fontSize='inherit' />
                    </IconButton>
                  </Box>
                </Box>
              ))
            }
          </Stack>
        )
      }
      <Button
        variant='text'
        size='small'
        color='primary'
        onClick={handleAddNewClick}
        startIcon={<Add />}
      >
        Add another item
      </Button>
    </Box>
  )
}
