import AppSettings from '@/config/main'
import { useAppContext } from '@/context/app.context'
import { useFlagsContext } from '@/context/flags.context'
import { MenuOpen } from '@mui/icons-material'
import { Box } from '@mui/material'
import cx from 'classnames'
import React, { useCallback, useMemo, useTransition } from 'react'
import { useNavigate } from 'react-router-dom'
import LogoSection from './LogoSection'
import ProfileSection from './ProfileSection'
import { useTranslation } from 'react-i18next'

type SidebarProps = {
  className: string,
  isOpen: boolean,
}
export default function Sidebar({ className, isOpen }: SidebarProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isChangingPage, startChangePageTransition] = useTransition();

  const { isFlagEnabled } = useFlagsContext();
  const { smallScreen, setSidebarOpen, toggleSidebar, selectedPage, setSelectedPage } = useAppContext();

  const menus = useMemo(() => AppSettings.mainMenu.list, []);
  const handleOnClickMenu = useCallback((page) => {
    startChangePageTransition(() => {
      setSelectedPage(page);
      navigate(page.url);
      if (smallScreen) {
        setSidebarOpen(false);
      }
    });
  }, [selectedPage, smallScreen])

  return (
    <Box
      component='div'
      className={className}
    >
      <Box className='logo-section'>
        <LogoSection  showBigLogo={isOpen} />
      </Box>
      <Box component='div' className='sidebar-menu'>
        {
          menus.map(menuPage => {
            if (!!menuPage.validateFlagKey && !isFlagEnabled(menuPage.validateFlagKey)) {
              return null;
            }

            return (
              <Box
                key={`menu-item-${menuPage.id}`}
                component={menuPage.urlType === 'internal' ? 'div' : 'a'}
                className={cx('sidebar-menu-item', {
                  'selected': (!!selectedPage && menuPage.id === selectedPage.id)
                })}
                href={menuPage.urlType === 'external' ? menuPage.url : undefined}
                target={menuPage.urlType === 'external' ? '_blank' : undefined}
                onClick={menuPage.urlType === 'internal' ? () => handleOnClickMenu(menuPage) : undefined}
              >
                <span className='sidebar-menu-item-icon'>
                  {menuPage.icon}
                </span>
                <span className={cx('sidebar-menu-item-label')}>
                  {t(menuPage.titleKey, { ns: 'common' })}
                </span>
              </Box>
            )
          })
        }
      </Box>
      <div className='sidebar-footer'>
        <ProfileSection className='profile-section' showName={false} />
        <Box component='div' onClick={toggleSidebar} className='sidebar-toggle'>
          <MenuOpen sx={{
            transform: `rotate(${isOpen ? 0 : 180}deg)`,
            transition: 'transform .3s ease-in-out'
          }}
          />
        </Box>
      </div>
    </Box>
  )
}
