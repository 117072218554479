import LoginButton from '@/ui-component/button/LoginButton'
import { Box } from '@mui/material'
import React from 'react'
import SubscriptionBadge from './SubscriptionBadge'

export default function ProfileSection({ className, showName }) {
  return (
    <Box component='div' className={className}>
      <SubscriptionBadge>
        <LoginButton showName={showName} />
      </SubscriptionBadge>
    </Box>
  )
}
