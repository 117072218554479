
export abstract class BaseModel {

  static load(data: any) {
    
  }

  toJSON() {
    
  }
}