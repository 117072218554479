import colors from '@/assets/scss/_themes-vars.module.scss';

export const AppColors = {
  paper: colors.paper,
  primaryLight: colors.primaryLight,
  primary200: colors.primary200,
  primaryMain: colors.primaryMain,
  primaryDark: colors.primaryDark,
  primary800: colors.primary800,
  secondaryLight: colors.secondaryLight,
  secondary200: colors.secondary200,
  secondaryMain: colors.secondaryMain,
  secondaryDark: colors.secondaryDark,
  secondary800: colors.secondary800,
  successLight: colors.successLight,
  success200: colors.success200,
  successMain: colors.successMain,
  successDark: colors.successDark,
  errorMain: colors.errorMain,
  errorDark: colors.errorDark,
  orangeLight: colors.orangeLight,
  orangeMain: colors.orangeMain,
  orangeDark: colors.orangeDark,
  tealLight: colors.tealLight,
  tealMain: colors.tealMain,
  tealDark: colors.tealDark,
  warningLight: colors.warningLight,
  warningMain: colors.warningMain,
  warningDark: colors.warningDark,
  grey20: colors.grey20,
  grey50: colors.grey50,
  grey100: colors.grey100,
  grey200: colors.grey200,
  grey300: colors.grey300,
  grey500: colors.grey500,
  grey600: colors.grey600,
  grey700: colors.grey700,
  grey800: colors.grey800,
  grey900: colors.grey900,
  darkPaper: colors.darkPaper,
  darkBackground: colors.darkBackground,
  darkLevel1: colors.darkLevel1,
  darkLevel2: colors.darkLevel2,
  darkTextTitle: colors.darkTextTitle,
  darkTextPrimary: colors.darkTextPrimary,
  darkTextSecondary: colors.darkTextSecondary,
  darkPrimaryLight: colors.darkPrimaryLight,
  darkPrimaryMain: colors.darkPrimaryMain,
  darkPrimaryDark: colors.darkPrimaryDark,
  darkPrimary200: colors.darkPrimary200,
  darkPrimary400: colors.darkPrimary400,
  darkPrimary800: colors.darkPrimary800,
  darkSecondaryLight: colors.darkSecondaryLight,
  darkSecondaryMain: colors.darkSecondaryMain,
  darkSecondaryDark: colors.darkSecondaryDark,
  darkSecondary200: colors.darkSecondary200,
  darkSecondary800: colors.darkSecondary800,
  transparent: colors.transparent,
}