import React, { ReactNode } from 'react';
import {
  IconAppWindow, IconBuildingStore, IconTool,
  IconRobot, IconLock, IconVariable,
  IconKey, IconCreditCard, IconHelp
} from "@tabler/icons";

type MenuType = {
  id: string,
  titleKey: string,
  type: 'item',
  icon: ReactNode,
  urlType: 'internal' | 'external',
  url: string,
  validateFlagKey?: string
};
 
const menuMap: Record<string, MenuType> = {
  '/chatflows': {
    id: 'chatflows',
    titleKey: 'menu_chatbots',
    type: 'item',
    icon: <IconAppWindow size={'1.3rem'} />,
    urlType: 'internal',
    url: '/chatflows',
  },
  '/marketplaces': {
    id: 'marketplaces',
    titleKey: 'menu_marketplaces',
    type: 'item',
    icon: <IconBuildingStore size={'1.3rem'} />,
    urlType: 'internal',
    url: '/marketplaces',
    validateFlagKey:'isMarketplaceEnabled'
  },
  '/tools': {
    id: 'tools',
    titleKey: 'menu_tools',
    type: 'item',
    icon: <IconTool size={'1.3rem'} />,
    urlType: 'internal',
    url: '/tools',
  },
  '/assistants': {
    id: 'assistants',
    titleKey: 'menu_assistants',
    type: 'item',
    icon: <IconRobot size={'1.3rem'} />,
    urlType: 'internal',
    url: '/assistants',
  },
  '/credentials': {
    id: 'credentials',
    titleKey: 'menu_credentials',
    type: 'item',
    icon: <IconLock size={'1.3rem'} />,
    urlType: 'internal',
    url: '/credentials',
  },
  '/variables': {
    id: 'variables',
    titleKey: 'menu_variables',
    type: 'item',
    icon: <IconVariable size={'1.3rem'} />,
    urlType: 'internal',
    url: '/variables',
  },
  '/apikey': {
    id: 'apikey',
    titleKey: 'menu_api_keys',
    type: 'item',
    icon: <IconKey size={'1.3rem'} />,
    urlType: 'internal',
    url: '/apikey',
  },
  '/subscription': {
    id: 'subscriptions',
    titleKey: 'menu_my_subscriptions',
    type: 'item',
    icon: <IconCreditCard size={'1.3rem'} />,
    urlType: 'internal',
    url: '/subscription',
  },
  '/guides': {
    id: 'guides',
    titleKey: 'menu_guides',
    type: 'item',
    icon: <IconHelp size={'1.3rem'} />,
    urlType: 'external',
    url: 'https://docs.gaiahub.ai',
    validateFlagKey: 'isGuideMenuEnabled'
  }
}

export const menus = Object.values(menuMap)
export const menusKeys = Object.keys(menuMap)