import { CredentialPicker, DrawerContainer, DrawerLayerContent, DrawerLayerFooter, DrawerLayerHeader, FileInput, IconPicker } from '@/components'
import { PropsWithCloseDrawer } from '@/components/drawer-layer/drawer-layer.types'
import FormCustomElement from '@/components/form/FormCustomElement';
import { useSnackbarNotification } from '@/hooks/snackbar.hooks';
import { Assistant } from '@/models/Assistant';
import useNotifier from '@/utils/useNotifier';
import { Save } from '@mui/icons-material';
import { Alert, Button, Stack } from '@mui/material';
import React, { useMemo } from 'react'
import { AutocompleteElement, FormContainer, SelectElement, TextareaAutosizeElement, TextFieldElement } from 'react-hook-form-mui';
import { assistantAvailableModels } from './constants';
import { useCreateAssistantMutation, useUpdateAssistantMutation } from '@/hooks/assistants.hooks';
import { t } from 'i18next';

type AssistantFormProps = {
  assistant?: Assistant
}
export default function AssistantForm(props: PropsWithCloseDrawer<AssistantFormProps>) {
  useNotifier();
  const { sucessSnackbar, errorSnackbar } = useSnackbarNotification();
  const { closeDrawer, assistant } = props;
  const assistantModel = useMemo<Assistant | any>(() => {
    if (!assistant)
      return {}
    return {
      ...assistant,
      details: {
        ...assistant.detailsData
      }
    }
  }, [assistant]);

  const createAssistant = useCreateAssistantMutation();
  const updateAssistant = useUpdateAssistantMutation();

  const handleOnSubmit = async (values) => {
    try {
      const obj = {
        ...values,
        details: {
          ...values.details,
          id: assistantModel.id || '',
          tools: values.details.tools.map(x => (typeof x === 'string') ? x : x.id),
          uploadFiles: values.files
        }
      }
      const instance = Assistant.load(obj);
      if (!!instance.id) {
        const result = await updateAssistant.mutateAsync(instance);

        sucessSnackbar(t('assistant.update_sucess'))
        closeDrawer(result);
      } else {
        const result = await createAssistant.mutateAsync(instance);
        sucessSnackbar(t('assistant.create_success'))
        closeDrawer(result);
      }
    } catch (error) {
      console.log(error);
      errorSnackbar(t('common:delete_error', { error: error.message }));
    }
  }

  return (
    <DrawerContainer
      width={800}
      as={FormContainer}
      onSuccess={handleOnSubmit}
      values={assistantModel}
    >
      <DrawerLayerHeader
        title={!!assistantModel.id ? t('assistant.form.title_update') : t('assistant.form.title_create')}
        onClose={closeDrawer}
      />
      <DrawerLayerContent>
        <Stack direction='row' gap={2} alignItems={'center'}>
          <FormCustomElement
            name='iconFile'
            as={IconPicker}
            resultType='file'
            defaultImage={assistantModel.iconSrc}
          />
          <TextFieldElement
            name='details.name'
            label={t('assistant.form.field_name')}
            fullWidth
            required
            helperText={t('assistant.form.field_name_helper')}
          />
        </Stack>
        <TextareaAutosizeElement
          name='details.description'
          label={t('assistant.form.field_description')}
          rows={5}
          maxRows={10}
          placeholder={t('assistant.form.field_description_placeholder')}
          helperText={t('assistant.form.field_description_helper')}
        />
        <SelectElement
          name='details.model'
          label={t('assistant.form.field_model')}//''
          placeholder={t('assistant.form.field_model_placeholder')}//''
          valueKey='name'
          required
          labelKey='label'
          options={assistantAvailableModels}
        />
        <FormCustomElement
          as={CredentialPicker}
          label={t('assistant.form.field_credential')}//''
          name='credential'
          findKey='id'
          required
          filter={{ credentialLibrary: { type: `$eq:openAIApi` } }}
        />
        <TextareaAutosizeElement
          name='details.instructions'
          label={t('assistant.form.field_instructions')}//''
          placeholder={t('assistant.form.field_instructions_placeholder')}//''
          rows={5}
          required
          maxRows={10}
          helperText={t('assistant.form.field_instructions_helper')}//''
        />
        <AutocompleteElement
          name='details.tools'
          label={t('assistant.form.field_tools')}//''
          multiple
          matchId
          options={[
            {
              label: t('assistant.form.field_tools_option_code_intepreter'),//'',
              id: 'code_interpreter'
            },
            {
              label: t('assistant.form.field_tools_option_retrieval'),
              id: 'retrieval'
            }
          ]}
        />
        <FormCustomElement
          as={FileInput}
          label={t('assistant.form.field_files')}//''
          fileType={'.c,.cs,.cpp,.doc,.docx,.html,.java,.json,.md,.pdf,.php,.pptx,.py,.py,.rb,.tex,.txt,.css,.js,.sh,.ts'}
          name='details.uploadFiles'
          valueFormatter={(inputs) => inputs.map(item => item.raw)}
          limit={5}
          helperText={t('assistant.form.field_files_helper')}//''
        />
        {
          !!assistantModel.details?.files && assistantModel.details?.files?.length > 0 && (
            <Alert variant='filled' color='info'>
              {/*  */}
              {t('assistant.form.field_files_warningMsg', { count: assistantModel.details.files.length })}
            </Alert>
          )
        }
      </DrawerLayerContent>
      <DrawerLayerFooter center>
        <Button
          type='submit'
          variant='contained'
          sx={{ width: 400 }}
          startIcon={<Save />}
        >
          {t('assistant.form.button_save')}
        </Button>
      </DrawerLayerFooter>
    </DrawerContainer>
  )
}
