import { useLoadAllTools } from '@/hooks/tool.hooks';
import ToolForm from '@/views/tools/ToolForm';
import { Add } from '@mui/icons-material';
import { Autocomplete, Avatar, Box, Button, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DrawerLayer from '../drawer-layer/DrawerLayer';
import NodeAvatar from '../avatar/NodeAvatar';
import { Assistant } from '@/models/Assistant';
import { useLoadAllAssistants } from '@/hooks/assistants.hooks';
import AssistantForm from '@/views/assistants/AssistantForm';

type AssistantPickerProps = {
  value?: string;
  label?: string;
  disabled?: boolean;
  findKey?: string;
  onChange?: (assistantId: string, assistant?: Assistant) => void;
  formatValue?: (model: Assistant) => any;
}

export default function AssistantPicker(props: AssistantPickerProps) {
  const { value, onChange, disabled, formatValue, findKey = 'id' } = props;
  const { data: assistants, isLoading, refetch, remove: removeFromCache } = useLoadAllAssistants();
  const [choosed, setChoosed] = useState<Assistant | null>(null);

  const handleOnCreateNewAssistant = async (assistant: Assistant) => {
    removeFromCache();
    await refetch();
    setChoosed(assistant);
  }

  useEffect(() => {
    if (!!choosed) {
      const returnValue = !!formatValue ? formatValue(choosed) : choosed.id;
      !!onChange && onChange(returnValue, choosed);
    }
  }, [choosed])

  useEffect(() => {
    if (!choosed && (assistants && assistants.length > 0) && value) {
      const c = assistants.find(x => x[findKey] === value);
      setChoosed(c);
    }
  }, [assistants, value, choosed]);

  useEffect(() => {
    return () => {
      removeFromCache();//remove cache when component is unmounted
    }
  }, []);

  return (
    <Stack direction='column' gap={1}>
      <Stack direction='row' alignItems='center'>
        <Box flexGrow={1}>
          {!!choosed && (
            <Stack direction='row' alignItems='center' gap={2}>
              <Avatar src={choosed.iconSrc} sx={{ width: 40, height: 40 }} />
              <Typography variant='label'>{choosed.detailsData.name}</Typography>
            </Stack>
          )}
        </Box>
        <DrawerLayer
          content={(openDrawer) => (
            <Button
              variant='outlined'
              size='small'
              color='secondary'
              property='action'
              onClick={openDrawer}
              startIcon={<Add />}
            >
              Create new Assistant
            </Button>
          )}
          as={AssistantForm}
          onClose={handleOnCreateNewAssistant}
        />
      </Stack>
      <Box>
        <Autocomplete
          disableClearable={true}
          options={assistants || []}
          loading={isLoading}
          value={choosed}
          disabled={disabled}
          onChange={(e, selection) => {
            setChoosed(selection)
          }}
          getOptionKey={option => option.id}
          getOptionLabel={(option) => option.detailsData.name}
          renderInput={(params) => <TextField {...params} placeholder='Search Assistant by Name' />}
          renderOption={(props, option) => (
            <Box component='li' {...props}>
              <Avatar src={option.iconSrc} sx={{ width: 40, height: 40 }}>
                {option.detailsData.name.charAt(0).toUpperCase()}
              </Avatar>
              <Typography ml={1} variant='h5'>{option.detailsData.name}</Typography>
            </Box>
          )}
        />
      </Box>
    </Stack>
  )
}
