
import { DrawerLayer, ReadMore } from '@/components';
import { Tool } from '@/models/Tool';
import ToolForm from '@/views/tools/ToolForm';
import { Bolt, CopyAllRounded, Delete, Download, Edit, List } from '@mui/icons-material';
import { Avatar, Box, Card, CardActions, CardContent, CardHeader, Chip, IconButton, Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import React from 'react';
import imagePlaceholder from '@/assets/images/default-image.svg';
import AvatarComponent from '@/ui-component/avatar-component/AvatarComponent';

type ToolCardProps = {
  tool: Tool,
  showCopy?: boolean;
  showEdit?: boolean;
  isLoading?: Boolean,
  onClickDelete?: (tool: Tool) => void;
  onClickDownload?: (tool: Tool) => void;
};

const styleTextEllipses = {
  width: '100%',
  display: 'block',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
};

export default function ToolCard({
  tool,
  onClickDelete,
  showEdit,
  showCopy,
  onClickDownload,
  isLoading = false
}: ToolCardProps) {
  return (
    <Card variant='outlined'>
      <CardHeader
        avatar={isLoading ? (<Skeleton variant='circular' width={40} height={40} />) : (
          <Avatar
            src={tool.iconSrc || imagePlaceholder}
          />
        )}
        title={isLoading ? <Skeleton variant='text' width={100} /> : tool.name}
        action={<AvatarComponent type='tool' />}
        titleTypographyProps={styleTextEllipses}
        subheader={isLoading ? <Skeleton variant='text' width={80} height={20} /> : tool.code || '-'}
        subheaderTypographyProps={styleTextEllipses}
      />
      <CardContent sx={{ minHeight: 100 }} >
        <Typography variant='body1'>
          {isLoading ? (
            <>
              <Skeleton variant='text' height={20} />
              <Skeleton variant='text' height={20} />
              <Skeleton variant='text' height={20} />
            </>
          ) : (
            <ReadMore
              content={tool.description || 'no description'}
            />
          )}
        </Typography>
      </CardContent>
      <Stack direction='row' gap={1} flexWrap='wrap' height={50} alignItems={'center'} px={2}>
        {
          !isLoading && (
            <>
              {
                tool.shareable ? (
                  <Tooltip title='Is' placement='top'>
                    <Chip size='small' label='Public' color='success' />
                  </Tooltip>
                ) : (
                  <Tooltip title='Is' placement='top'>
                    <Chip size='small' label='Private' color='default' />
                  </Tooltip>
                )
              }
              {Object.keys(tool.schema || {}).length > 0 && (
                <Tooltip title='Has Properties' placement='top'>
                  <Avatar alt='Has Properties' sx={{ width: 24, height: 24, bgcolor: 'background.default', padding: 2 }}>
                    <List color='primary' />
                  </Avatar>
                </Tooltip>
              )}
              {
                (tool.func || '').length > 0 && (
                  <Tooltip title='Has Function' placement='top'>
                    <Avatar alt='Has Function' sx={{ width: 24, height: 24, bgcolor: 'background.default', padding: 2 }}>
                      <Bolt color='primary' />
                    </Avatar>
                  </Tooltip>
                )
              }
            </>
          )
        }
      </Stack>
      <CardActions disableSpacing>
        {
          isLoading && (
            <Skeleton variant='text' />
          )
        }
        {
          !isLoading && (
            <>
              {!!onClickDownload && (
                <IconButton
                  aria-label="download tool"
                  property='action'
                  color='secondary'
                  onClick={() => onClickDownload(tool)}
                >
                  <Download />
                </IconButton>
              )}
              {
                showCopy && (
                  <DrawerLayer
                    content={(openDrawer) => (
                      <IconButton
                        color='secondary'
                        property='action'
                        aria-label='Copy Tool'
                        onClick={openDrawer}
                      >
                        <CopyAllRounded />
                      </IconButton>
                    )}
                    as={ToolForm}
                    toolModel={{
                      ...tool,
                      name: `Copy of ${tool.name}`
                    }}
                    actionType='copy'
                  />
                )
              }
              <Box
                display={'flex'}
                flexGrow={1}
                justifyContent={'flex-end'}
              >
                {showEdit && (
                  <DrawerLayer
                    content={(openDrawer) => (
                      <IconButton
                        aria-label="edit"
                        property='edit'
                        color='secondary'
                        onClick={openDrawer}
                      >
                        <Edit />
                      </IconButton>
                    )}
                    as={ToolForm}
                    toolModel={tool}
                    actionType='standart'
                  />
                )}
                {!!onClickDelete && (
                  <IconButton
                    aria-label="delete"
                    property='delete'
                    color='secondary'
                    onClick={() => onClickDelete(tool)}
                  >
                    <Delete />
                  </IconButton>
                )}
              </Box>
            </>
          )
        }
      </CardActions>
    </Card>
  )
}
