
import { useQuery } from "react-query"
import client from "@/api/client";
import moment from "moment";
import { CredentialLibrary } from "@/models/CredentialComponent";

export enum CredentialLibraryRequests {
  all = 'credential-library.all',
  detail = 'credential-library.detail'
}

export const useCredentialsComponentsLoadAll = () => {
  return useQuery<CredentialLibrary[], Error>(CredentialLibraryRequests.all, async () => {
    const { data } = await client.get("/credential-library");
    return data.map(CredentialLibrary.load)
  }, {
    initialData: [],
    refetchInterval: moment.duration(1, 'day').milliseconds(),
    refetchOnWindowFocus: false
  });
}