import React, { useEffect, useMemo } from 'react'
import GridView, { GridViewProps } from './GridView';
import { IPagination, PaginationProviderParams } from '@/typings';
import { usePaginationProvider, usePaginationState } from '@/hooks/pagination.hooks';
import { Pagination } from '@/components'
type GridViewPaginatedProps<T> = {
  /**
   * DataTable used to render DataTable component
   */
  GridViewProps: Omit<GridViewProps<T>, "data">;
  /**
   * Pagination Provider Params
   * @type PaginationProviderParams
   */
  paginationProviderParams: PaginationProviderParams<T>;
}

export default function GridViewPaginated<T>(props: GridViewPaginatedProps<T>) {
  const { GridViewProps, paginationProviderParams } = props;
  const { pageSize, currentPage, setPageSize, setCurrentPage } = usePaginationState();
  const { data: pagination, isLoading, isError, refetch, isPreviousData } = usePaginationProvider({
    ...paginationProviderParams,
    paginationParams: {
      ...paginationProviderParams.paginationParams,
      currentPage,
      pageSize
    }
  });
  const { data, ...rest } = useMemo(() => !!pagination ? pagination : { data: [], }, [pagination]);

  useEffect(() => {
    refetch();
  }, [pageSize, currentPage]);

  return (
    <>
      {
        !!rest && (
          <Pagination
            pagination={rest as IPagination}
            onPageSizeChange={!isPreviousData && setPageSize}
            onPageClick={!isPreviousData && setCurrentPage}
            sx={{
              my:2
            }}
          />
        )
      }
      <GridView<T>
        {...GridViewProps}
        data={data || []}
        isLoading={isLoading}
      />
    </>
  )
}
