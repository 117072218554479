import { DrawerContainer, DrawerLayerContent, DrawerLayerFooter, DrawerLayerHeader, IconPicker, InputTags } from '@/components';
import { PropsWithCloseDrawer } from '@/components/drawer-layer/drawer-layer.types';
import FormCustomElement from '@/components/form/FormCustomElement';
import { useCreateChatflowMutation, useIncrementChatflowCopyCount, useUpdateChatflowMutation } from '@/hooks/chatflow.hooks';
import { useSnackbarNotification } from '@/hooks/snackbar.hooks';
import { ChatFlow } from '@/models/ChatFlow';
import { ChatFlowTemplate } from '@/models/ChatFlowTemplate';
import useNotifier from '@/utils/useNotifier';
import { Button, Stack } from '@mui/material';

import React, { useEffect, useMemo } from 'react';
import { FormContainer, SwitchElement, TextFieldElement } from 'react-hook-form-mui';
import { useTranslation } from 'react-i18next';
import { clearAllCredentials } from './utils';

type ChatflowFormProps = {
  chatflow?: any,
  actionType?: 'copy' | 'standart'
}

export default function ChatflowForm(props: PropsWithCloseDrawer<ChatflowFormProps>) {
  useNotifier()
  const { t } = useTranslation();
  const { chatflow, closeDrawer, actionType = 'standart' } = props
  const { sucessSnackbar, errorResponseSnackbar } = useSnackbarNotification();

  const model = useMemo(() => {
    if (!!chatflow && actionType === 'copy') {
      if (chatflow instanceof ChatFlowTemplate) {
        const { nodes, edges, name } = chatflow
        return {
          id: undefined,
          name: `Copy of - ${name}`,
          description: '',
          flowConfig: {
            nodes,
            edges,
          }
        }
      }

      return {
        ...clearAllCredentials(chatflow),
        id: undefined,
        name: `Copy of - ${chatflow.name}`,
        description: '',
      }
    }
    else if (!!chatflow) {
      return chatflow;
    }
    return {}
  }, [chatflow]);

  const createChatflow = useCreateChatflowMutation();
  const updateChatflow = useUpdateChatflowMutation();
  const incrementChatflowCopyCount = useIncrementChatflowCopyCount();

  const drawerTitle = useMemo(() => actionType === 'copy' ? t('common:copy') : !!chatflow ? t('common:update') : t('common:create'), []);

  const handleOnSubmitClick = async (data: any) => {
    try {
      const instance = ChatFlow.load(data);
      if (actionType === 'copy') {
        //increment copy count
        incrementChatflowCopyCount.mutateAsync(chatflow.id);

        //always create new
        const result = await createChatflow.mutateAsync(instance);
        !!closeDrawer && closeDrawer(result);
        sucessSnackbar(t('Chatbot created'))
      }
      else {

        if (!!instance.id) {
          const result = await updateChatflow.mutateAsync(instance);
          !!closeDrawer && closeDrawer(result);
          sucessSnackbar(t('Chatbot updated'))
        }
        else {
          const result = await createChatflow.mutateAsync(instance);
          !!closeDrawer && closeDrawer(result);
          sucessSnackbar(t('Chatbot created'))
        }
      }
    } catch (error) {
      errorResponseSnackbar(error)
      !!closeDrawer && closeDrawer();
    }
  }

  useEffect(() => {

  }, [chatflow])

  return (
    <DrawerContainer as={FormContainer} defaultValues={model} onSuccess={handleOnSubmitClick} minWidth={500} maxWidth={500}>
      <DrawerLayerHeader
        title={`${drawerTitle} Chatflow`}
        onClose={closeDrawer}
      />
      <DrawerLayerContent>
        <Stack direction='row' gap={2}>
          <FormCustomElement
            name='iconFile'
            as={IconPicker}
            resultType='file'
            defaultImage={model.icon}
          />
          <TextFieldElement name='name' label={t('chatflow.form.field_name')} required fullWidth />
        </Stack>
        <SwitchElement name='public' label={t('chatflow.form.field_is_public')} />
        <SwitchElement name='shareable' label={t('chatflow.form.field_is_shareable', 'Is this Chatbot shareable?')} />
        <TextFieldElement name='description' label={t('chatflow.form.field_description')} multiline rows={10} required fullWidth />
        <FormCustomElement
          name='category'
          label={t('chatflow.form.field_category')}
          as={InputTags}
          mode='edit'
          valueFormatter={(v: string) => !!v ? v.split(';') : []}
          resultFormatter={v => v.join(';')}
        />
      </DrawerLayerContent>
      <DrawerLayerFooter center>
        <Button
          variant='contained'
          color='primary'
          sx={{
            width: 400
          }}
          type='submit'
        >
          {actionType === 'standart' && !!model.id && t('chatflow.form.submit_update')}
          {actionType === 'standart' && !model.id && t('chatflow.form.submit_create')}
          {actionType === 'copy' && t('chatflow.form.submit_copy')}
        </Button>
      </DrawerLayerFooter>
    </DrawerContainer>
  )
}
