import { dataURLtoFile, getFileAsString } from "@/utils/file";

export interface FileInputValue {
  raw: string;
  filename: string;
  extension: string;
  base64: string;
  file: File;
}

export async function convertFileIntoFileInputValue(file: File): Promise<FileInputValue> {
  const base64 = await getFileAsString(file);
  const filename = file.name;
  const extension = filename.split('.').pop();
  return {
    raw: `${base64},filename:${file.name}`,
    filename,
    extension,
    base64,
    file
  } as FileInputValue;
}

export async function convertRawToFileInputValue(value: string): Promise<FileInputValue> {
  const [base64, filename] = value.split(',filename:')
  const extension = '';
  const file = dataURLtoFile(base64, filename);

  return {
    raw: value,
    filename,
    extension,
    base64,
    file
  } as FileInputValue;
}