import ReactReadMoreReadLess from 'react-read-more-read-less'
import React from 'react'
import { useTheme } from '@mui/material'

type ReadMoreProps = {
  content: string,
  limit?: number
}
export default function ReadMore(props: ReadMoreProps) {
  const { palette } = useTheme()
  const { limit = 100, content } = props;

  return (
    <ReactReadMoreReadLess
      charLimit={limit}
      readMoreText={"more ▼"}
      readLessText={"less ▲"}
      readMoreStyle={{
        color: palette.primary.main,
        textDecoration: 'underline',
        cursor: 'pointer'
      }}
      readLessStyle={{
        color: palette.primary.main,
        textDecoration: 'underline',
        cursor: 'pointer'
      }}
    >
      {content || ''}
    </ReactReadMoreReadLess>
  )
}
