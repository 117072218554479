import { ApiDataResult } from "@/typings";
import { Product } from "./Product";
import moment from "moment";

export class Subscription {
  id: string;
  productId: string;
  userId: string
  create_date: Date;
  update_date: Date;
  status: string;
  subscription_start_date: Date;
  subscription_end_date: Date;
  product?: Product;

  static load(data: ApiDataResult): Subscription {
    const model = new Subscription();

    model.id = data["id"];
    model.productId = data["productId"];
    model.userId = data["id"];
    model.create_date = new Date(data["create_date"]);
    model.update_date = new Date(data["update_date"]);
    model.subscription_start_date = new Date(data["subscription_start_date"]);
    model.subscription_end_date = new Date(data["subscription_end_date"]);

    model.status = data["status"];

    if ('product' in data) {
      model.product = Product.load(data['product'])
    }

    return model;
  }

  isFreeSubscription() {
    return (!this.product || this.product.code === 'gaia-free');
  }

  hasPeriod() {
    return (this.subscription_start_date != null && this.subscription_end_date !== null);
  }

  isSubscriptionExpired() {
    if (this.hasPeriod()) {
      const current = moment();
      const dateEnd = moment(this.subscription_end_date);
      return current.isAfter(dateEnd);
    }
    return false;
  }
}