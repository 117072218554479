import { Avatar, Box, SxProps } from '@mui/material'
import React from 'react'


type NodeAvatarProps = {
  /** 
   * @type string
   * @description url of the icon 
   * @
  */
  iconUrl: string,
  /** 
   * Defines the size of the container 
   * @default 48
  */
  size?: number,
  /**
   * @default undefined
   */
  title?: string,

  /**
   * @default grey.dark
   */
  bgcolor?: string,
  /**
   * @default background.default
   */
  iconColor?: string;
  sx?: SxProps;
}
export default function NodeAvatar(props: NodeAvatarProps) {
  const { iconUrl, size = 48, title, bgcolor = 'background.default', iconColor = 'primary.main', sx } = props;
  return (
    <Avatar
      variant='circular'
      sx={{
        bgcolor,
        p: 1,
        width: size,
        height: size,
        ...sx
      }}
    >
      <Box component='div'
        title={title}
        sx={{
          bgcolor: iconColor,
          width: size,
          height: size,
          maskImage: `url(${iconUrl})`,
          maskPosition: 'center',
          maskRepeat: 'no-repeat',
          maskSize: 'contain',
        }}
      />
    </Avatar>
  )
}
