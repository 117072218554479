import { ChatFlow } from '@/models/ChatFlow'
import { Box, IconButton, Stack } from '@mui/material'
import React, { useEffect, useMemo, useRef, useState, useTransition } from 'react'
import { ChatbotContext } from './ChatbotContext'

import { useLoadChatflowInternalMessages, useLoadChatflowIsStreaming, useSendChatflowInternalMessagePrediction } from '@/hooks/chatflow.hooks'
import { baseURL } from '@/store/constant'
import { IconSend } from '@tabler/icons'
import { FormContainer, TextFieldElement, useForm } from 'react-hook-form-mui'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { io } from 'socket.io-client'
import { ChatbotMessage, MessageTypes } from '../../typings'
import './chatbot.styles.scss'
import Message from './components/Message'
import { socket } from '@/utils/socket'
import ScrollBar from 'react-perfect-scrollbar'

type ChatbotProps = {
  chatflow: ChatFlow,
  onConnectionChange?: (connectionFlag: boolean) => void
}
export default function Chatbot(props: ChatbotProps) {
  const { chatflow, onConnectionChange } = props;
  const psRef = useRef<ScrollBar | null>(null);
  const messageContainerRef = useRef<HTMLElement | null>(null);

  // const { data: isStreaming } = useLoadChatflowIsStreaming(chatflow.id);
  // const sendMessagePrediction = useSendChatflowInternalMessagePrediction();
  // const { data: internalMessages } = useLoadChatflowInternalMessages(chatflow.id);
  // const chatbotMessages = useMemo(() => (internalMessages || []).map(msg => ({

  // } as ChatbotMessage)), [internalMessages]);

  const chatflowConfig = useMemo(() => chatflow.chatbotConfig || {}, [chatflow]);

  const formContext = useForm<{
    inputMessage: string
  }>({
    defaultValues: {
      inputMessage: ''
    }
  });

  // const [isLoading, startMessageTransition] = useTransition();
  const [isChatbotOnline, setIsChatbotOnline] = useState<boolean>(false);
  // const [messages, setMessages] = useState<ChatbotMessage[]>(chatbotMessages);
  const [messages, setMessages] = useState<ChatbotMessage[]>([]);

  const context = useMemo(() => ({}), []);

  const addMessage = (message: ChatbotMessage) => {
    setMessages(prevMessages => {
      return [
        ...prevMessages,
        message
      ]
    })
    goToBottom();
  }

  const handleOnUserSendMessage = ({ inputMessage }) => {
    const userMessage = {
      type: MessageTypes.userMessage,
      message: inputMessage
    } as ChatbotMessage;
    addMessage(userMessage);
    socket.send(inputMessage)
    formContext.reset();
  }

  const goToBottom = () => {
    if (psRef.current?.updateScroll) {
      psRef.current.updateScroll();
    }

    setTimeout(() => {
      messageContainerRef.current.scrollTo({ top: Number.MAX_SAFE_INTEGER });
    }, 0);
  }

  useEffect(() => {
    if (!!messageContainerRef) {
      setTimeout(() => {
        goToBottom();
      }, 1000);
    }
  }, []);

  useEffect(() => {
    function onConnect() {
      console.log('Chat connected id:' + socket.id);
      socket.emit('chatbot-init', { chatbot: chatflow.id, user: 'teste' });
      setIsChatbotOnline(true);
      !!onConnectionChange && onConnectionChange(true);
    }

    function onDisconnect(reasons, details) {
      console.log('Disconnect', reasons, details);

      addMessage({
        type: MessageTypes.infoMessage,
        message: 'Chatbot is facing some issues! hang in there!'
      });

      setIsChatbotOnline(false);
      !!onConnectionChange && onConnectionChange(false);
    }

    function onMessage(message) {
      const apiMessage = {
        type: MessageTypes.apiMessage,
        message
      } as ChatbotMessage;
      addMessage(apiMessage)
    }

    function onInfo(message) {
      const infoMessage = {
        type: MessageTypes.infoMessage,
        message
      } as ChatbotMessage;
      addMessage(infoMessage)
    }

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('message', onMessage);
    socket.on('info', onInfo);

    socket.connect();

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('message', onMessage);
      socket.off('info', onInfo);

      socket.disconnect();
    }
  }, []);

  return (
    <ChatbotContext.Provider value={context}>
      <Stack direction='column' display='flex'>
        <Box
          component={PerfectScrollbar}
          flexGrow={1}
          className='chatbot-messages-container'
          ref={psRef}
          containerRef={(el) => messageContainerRef.current = el}
        >
          <Box
            component={Stack}
            direction='column'
            gap={2}
          >
            {messages.map((message, index) => (
              <Message key={`chatbot-message-${index}`} {...message} />
            ))}
          </Box>
        </Box>
        <FormContainer
          formContext={formContext}
          disabled={!isChatbotOnline}
          onSuccess={handleOnUserSendMessage}
        >
          <TextFieldElement
            autoFocus
            name='inputMessage'
            disabled={!isChatbotOnline}
            fullWidth
            sx={{
              p: 2
            }}
            InputProps={{
              endAdornment: <IconButton disabled={!isChatbotOnline} type='submit'>
                <IconSend />
              </IconButton>
            }}
          />
        </FormContainer>
      </Stack>
    </ChatbotContext.Provider>
  )
}
