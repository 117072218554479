import React, { ReactNode } from 'react'
import Modal from './Modal'
import { Dialog, DialogContent, DialogTitle } from '@mui/material'

type SimpleModalProps = {
  isOpen: boolean,
  size?: 'sm' | 'md' | 'lg' | 'xl',
  onClose: () => void
  title?: ReactNode | string;
  children: ReactNode;
}
export default function SimpleModal(props: SimpleModalProps) {
  const { isOpen, onClose, size = 'lg', title, children } = props;

  return (
    <Modal>
      <Dialog
        open={isOpen}
        fullWidth
        maxWidth={size}
        onClose={onClose}
      >
        {!!title && <DialogTitle variant='h2'>{title}</DialogTitle>}
        <DialogContent>{children}</DialogContent>
      </Dialog>
    </Modal>
  )
}
