import { DisplayViewTypes, displayViewConfigKey } from "./constants";

function getDisplayConfigs(): Record<string, string> {
  const displayConfigs = localStorage.getItem(displayViewConfigKey);
  return !!displayConfigs ? JSON.parse(displayConfigs) : {};
}

export function getStorageViewType(code: string): DisplayViewTypes | null {
  const displayConfig = getDisplayConfigs();
  return (code in displayConfig) ? displayConfig[code] as DisplayViewTypes : null;
}

export function setStorageViewType(code: string, viewType: DisplayViewTypes) {
  const displayConfig = getDisplayConfigs();
  displayConfig[code] = viewType;
  localStorage.setItem(displayViewConfigKey, JSON.stringify(displayConfig));
}
