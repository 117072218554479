import { Box, Stack } from '@mui/material'
import React, { PropsWithChildren } from 'react'

type DrawerLayerFooterProps = {
  center?: boolean,
}
export default function DrawerLayerFooter({ children, center = false }: PropsWithChildren<DrawerLayerFooterProps>) {
  return (
    <Stack
      component='div'
      direction='row'
      alignItems={'center'}
      justifyContent={!!center ? 'center' : 'space-between'}
      sx={{
        overflow: 'hidden',
        minHeight: 50,
        p:2
      }}
    >
      {children}
    </Stack>
  )
}
