import { ApiDataResult } from "../typings";

export class Product {
  id: string;
  title: string;
  metadata: string;
  provider: string;
  referenceId: string;
  code: string;
  updated_at: Date;
  create_at: Date;

  static load(data: ApiDataResult): Product {
    const product = new Product();
    product.id = data['id'];
    product.title = data['title'];
    product.metadata = data['metadata'];
    product.provider = data['provider'];
    product.referenceId = data['referenceId'];
    product.code = data['code'];
    product.create_at = new Date(data['create_at']);
    product.updated_at = new Date(data['updated_at']);
    return product;
  }
}