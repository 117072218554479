import React, { lazy } from 'react'

// project imports
import MinimalLayout from '@/layout/MinimalLayout'

// canvas routing
const ChatbotFull = lazy(() => import('@/views/chatbot'))

// ==============================|| CANVAS ROUTING ||============================== //

const ChatbotRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/chatbot/:id',
            element: <ChatbotFull />
        }
    ]
}

export default ChatbotRoutes
