import { PropsWithFormItem } from '@/ui-component/form/form-item.types';
import FormItemCustom from '@/ui-component/form/FormItemCustom';
import { Stack, Typography, Switch, SxProps } from '@mui/material'
import React from 'react'

type SwitchInputProps = {
  onChange: (checked: boolean) => void,
  defaultChecked?: boolean,
  prefixLabel?: string,
  suffixLabel?: string,
  sxContainerProps?: SxProps,
  sxPrefixLabelProps?: SxProps,
  sxSuffixLabelProps?: SxProps,
  sxSwitchProps?: SxProps,
}
export default function SwitchInput(props: PropsWithFormItem<SwitchInputProps>) {
  const {
    name, label, onChange, defaultChecked,
    prefixLabel = 'off', suffixLabel = 'on',
    sxContainerProps, sxPrefixLabelProps, sxSuffixLabelProps, sxSwitchProps
  } = props;
  
  return (
    <FormItemCustom
      name={name}
      label={label}
      component={(
        <Stack direction="row" spacing={1} alignItems="center" sx={sxContainerProps}>
          {!!prefixLabel && (<Typography sx={sxPrefixLabelProps}>{prefixLabel}</Typography>)}
          <Switch
            defaultChecked={defaultChecked}
            onChange={(_e, checked) => onChange(checked)}
            sx={sxSwitchProps}
          />
          {!!suffixLabel && (<Typography sx={sxSuffixLabelProps}>{suffixLabel}</Typography>)}
        </Stack>
      )}
    />
  )
}
