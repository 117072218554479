import { Box } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

type MainContentProps = {
}

export default function MainContent({ children }: PropsWithChildren<MainContentProps>) {
  return (
    <PerfectScrollbar
      className='main-content-root'
    >
      <Box component={'div'} className='main-content-inner'>
        {children}
      </Box>
    </PerfectScrollbar>
  )
}