import baseColors from '@/assets/scss/base-theme-vars.module.scss';
import { createTheme, Theme, ThemeOptions } from '@mui/material/styles';
import componentStyleOverrides from './compStyleOverride';
import { loadMixins } from './mixins';
import loadGaiaPalette from './palette';
import themeTypography from './typography';
import { mergeColors } from './utils';

export const createGaiaTheme = () => {
    const appColors = mergeColors(baseColors, {});
    const themeOptions: ThemeOptions = {
        direction: 'ltr',
        palette: loadGaiaPalette(appColors),
        typography: themeTypography(appColors),
        components: componentStyleOverrides(appColors)
    }
    const gaiaTheme: Theme = createTheme(themeOptions)
    return gaiaTheme
}

export default createGaiaTheme
