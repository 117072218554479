// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ``, "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"black": `#000`,
	"white": `#fff`,
	"paper": `#282828`,
	"paperDark": `#232323`,
	"background": `#1c1c1c`,
	"transparent": `rgba(255,255,255,0)`,
	"textPrimary": `#ededed`,
	"textSecondary": `#c4c3c3`,
	"textDisabled": `#8b8a8a`,
	"headingTextColor": `#ededed`,
	"headingTextColor2": `#c5c5c5`,
	"subtitle1": `#858585`,
	"subtitle2": `#858585`,
	"caption": `#c6c6c6`,
	"body1": `#c6c6c6`,
	"body2": `#c6c6c6`,
	"button": `#c6c6c6`,
	"primaryLight": `#70d09f`,
	"primaryMain": `#37996b`,
	"primaryDark": `#2b7a55`,
	"primary50": `#c7ebda`,
	"primary100": `#c7ebda`,
	"primary200": `#a1ddc1`,
	"primary300": `#7cd0a8`,
	"primary400": `#56c290`,
	"primary500": `#3da976`,
	"primary600": `#2f835c`,
	"primary700": `#225e42`,
	"primary800": `#214534`,
	"primary900": `#162e23`,
	"secondaryLight": `#d9d9d9`,
	"secondaryMain": `#bfbfbf`,
	"secondaryDark": `#8c8c8c`,
	"secondary50": `#f2f2f2`,
	"secondary100": `#d9d9d9`,
	"secondary200": `#bfbfbf`,
	"secondary300": `#a6a6a6`,
	"secondary400": `#8c8c8c`,
	"secondary500": `#737373`,
	"secondary600": `#595959`,
	"secondary700": `#404040`,
	"secondary800": `#282828`,
	"secondary900": `#0d0d0d`,
	"successLight": `#70d09f`,
	"successMain": `#37996b`,
	"successDark": `#007a79`,
	"success50": `#c7ebda`,
	"success100": `#c7ebda`,
	"success200": `#a1ddc1`,
	"success300": `#7cd0a8`,
	"success400": `#56c290`,
	"success500": `#3da976`,
	"success600": `#2f835c`,
	"success700": `#225e42`,
	"success800": `#225e42`,
	"success900": `#113021`,
	"errorLight": `#e7979a`,
	"errorMain": `#d1343a`,
	"errorDark": `#922125`,
	"error50": `#faeaeb`,
	"error100": `#f1c1c2`,
	"error200": `#e7979a`,
	"error300": `#de6d72`,
	"error400": `#d54449`,
	"error500": `#bb2a30`,
	"error600": `#922125`,
	"error700": `#68181b`,
	"error800": `#3e0e10`,
	"error900": `#150505`,
	"warningLight": `#e5d699`,
	"warningMain": `#d0b548`,
	"warningDark": `#bfa22f`,
	"warning50": `#faf7eb`,
	"warning100": `#efe6c2`,
	"warning200": `#e5d699`,
	"warning300": `#dbc671`,
	"warning400": `#d0b548`,
	"warning500": `#b79c2f`,
	"warning600": `#bfa22f`,
	"warning700": `#b5992d`,
	"warning800": `#a18929`,
	"warning900": `#8e7924`,
	"blueLight": `#1aaeff`,
	"blueMain": `#006196`,
	"blueDark": `#00314d`,
	"blue50": `#e5f6ff`,
	"blue100": `#b3e4ff`,
	"blue200": `#80d2ff`,
	"blue300": `#4dc0ff`,
	"blue400": `#1aaeff`,
	"blue500": `#0094e6`,
	"blue600": `#0073b3`,
	"blue700": `#005280`,
	"blue800": `#00314d`,
	"blue900": `#00101a`,
	"infoLight": `#744dcb`,
	"infoMain": `#5b34b2`,
	"infoDark": `#47288a`,
	"info50": `#f0ebf9`,
	"info100": `#d1c4ee`,
	"info200": `#b29ce2`,
	"info300": `#9375d7`,
	"info400": `#744dcb`,
	"info500": `#5b34b2`,
	"info600": `#47288a`,
	"info700": `#321d63`,
	"info800": `#1e113b`,
	"info900": `#0a0614`,
	"grey50": `#f2f2f2`,
	"grey100": `#d9d9d9`,
	"grey200": `#bfbfbf`,
	"grey300": `#a6a6a6`,
	"grey400": `#8c8c8c`,
	"grey500": `#737373`,
	"grey600": `#595959`,
	"grey700": `#404040`,
	"grey800": `#262626`,
	"grey900": `#0d0d0d`
};
export default ___CSS_LOADER_EXPORT___;
