export const assistantAvailableModels = [
  {
    label: 'gpt-4-1106-preview',
    name: 'gpt-4-1106-preview'
  },
  {
    label: 'gpt-4-0613',
    name: 'gpt-4-0613'
  },
  {
    label: 'gpt-4-0314',
    name: 'gpt-4-0314'
  },
  {
    label: 'gpt-4',
    name: 'gpt-4'
  },
  {
    label: 'gpt-3.5-turbo',
    name: 'gpt-3.5-turbo'
  },
  {
    label: 'gpt-3.5-turbo-1106',
    name: 'gpt-3.5-turbo-1106'
  },
  {
    label: 'gpt-3.5-turbo-0613',
    name: 'gpt-3.5-turbo-0613'
  },
  {
    label: 'gpt-3.5-turbo-16k',
    name: 'gpt-3.5-turbo-16k'
  },
  {
    label: 'gpt-3.5-turbo-16k-0613',
    name: 'gpt-3.5-turbo-16k-0613'
  }
]