import { baseURL } from "@/store/constant";
import cURLSVG from '@/assets/images/cURL.svg';
import EmbedSVG from '@/assets/images/embed.svg';
import javascriptSVG from '@/assets/images/javascript.svg';
import pythonSVG from '@/assets/images/python.svg';
import settingsSVG from '@/assets/images/settings.svg';
import ShareChatbotSVG from '@/assets/images/embed.svg';
import { getConfigExamplesForCurl, getConfigExamplesForJS, getConfigExamplesForPython, unshiftFiles } from '@/utils/genericHelper';
import { ChatFlow } from "@/models/ChatFlow";
import { node } from "prop-types";

export const getCode = (codeLang, chatflowId = '[CHATFLOW_ID]') => {
  if (codeLang === 'Python') {
    return `import requests

API_URL = "${baseURL}/api/v1/prediction/${chatflowId}"

def query(payload):
  response = requests.post(API_URL, json=payload)
  return response.json()
  
output = query({
  "question": "Hey, how are you?",
})
`;
  } else if (codeLang === 'JavaScript') {
    return `async function query(data) {
  const response = await fetch(
      "${baseURL}/api/v1/prediction/${chatflowId}",
      {
          method: "POST",
          headers: {
              "Content-Type": "application/json"
          },
          body: JSON.stringify(data)
      }
  );
  const result = await response.json();
  return result;
}

query({"question": "Hey, how are you?"}).then((response) => {
  console.log(response);
});
`;
  } else if (codeLang === 'cURL') {
    return `curl ${baseURL}/api/v1/prediction/${chatflowId} \\
   -X POST \\
   -d '{"question": "Hey, how are you?"}' \\
   -H "Content-Type: application/json"`;
  }
  return '';
};

export const getCodeWithAuthorization = (codeLang, chatflowId = '[CHATFLOW_ID]', apiKey = '[API_KEY]') => {
  if (codeLang === 'Python') {
    return `import requests

API_URL = "${baseURL}/api/v1/prediction/${chatflowId}"
headers = {"Authorization": "Bearer ${apiKey}"}

def query(payload):
  response = requests.post(API_URL, headers=headers, json=payload)
  return response.json()
  
output = query({
  "question": "Hey, how are you?",
})
`;
  } else if (codeLang === 'JavaScript') {
    return `async function query(data) {
  const response = await fetch(
      "${baseURL}/api/v1/prediction/${chatflowId}",
      {
          headers: {
              Authorization: "Bearer ${apiKey}",
              "Content-Type": "application/json"
          },
          method: "POST",
          body: JSON.stringify(data)
      }
  );
  const result = await response.json();
  return result;
}

query({"question": "Hey, how are you?"}).then((response) => {
  console.log(response);
});
`;
  } else if (codeLang === 'cURL') {
    return `curl ${baseURL}/api/v1/prediction/${chatflowId} \\
   -X POST \\
   -d '{"question": "Hey, how are you?"}' \\
   -H "Content-Type: application/json" \\
   -H "Authorization: Bearer ${apiKey}"`;
  }
  return '';
};

export const getLang = (codeLang) => {
  if (codeLang === 'Python') {
    return 'python';
  } else if (codeLang === 'JavaScript') {
    return 'javascript';
  } else if (codeLang === 'cURL') {
    return 'bash';
  }
  return 'python';
};

export const getSVG = (codeLang) => {
  if (codeLang === 'Python') {
    return pythonSVG;
  } else if (codeLang === 'JavaScript') {
    return javascriptSVG;
  } else if (codeLang === 'Embed') {
    return EmbedSVG;
  } else if (codeLang === 'cURL') {
    return cURLSVG;
  } else if (codeLang === 'Share Chatbot') {
    return ShareChatbotSVG;
  } else if (codeLang === 'Configuration') {
    return settingsSVG;
  }
  return pythonSVG;
};

export const getConfigCodeWithFormData = (codeLang, configData, chatflowId = '[CHATFLOW_ID]') => {
  if (codeLang === 'Python') {
    configData = unshiftFiles(configData);
    let fileType = configData[0].type;
    if (fileType.includes(',')) fileType = fileType.split(',')[0];
    return `import requests

API_URL = "${baseURL}/api/v1/prediction/${chatflowId}"

# use form data to upload files
form_data = {
  "files": ${`('example${fileType}', open('example${fileType}', 'rb'))`}
}
body_data = {${getConfigExamplesForPython(configData, 'formData')}}

def query(form_data):
  response = requests.post(API_URL, files=form_data, data=body_data)
  return response.json()

output = query(form_data)
`;
  } else if (codeLang === 'JavaScript') {
    return `// use FormData to upload files
let formData = new FormData();
${getConfigExamplesForJS(configData, 'formData')}
async function query(formData) {
  const response = await fetch(
      "${baseURL}/api/v1/prediction/${chatflowId}",
      {
          method: "POST",
          body: formData
      }
  );
  const result = await response.json();
  return result;
}

query(formData).then((response) => {
  console.log(response);
});
`;
  } else if (codeLang === 'cURL') {
    return `curl ${baseURL}/api/v1/prediction/${chatflowId} \\
   -X POST \\${getConfigExamplesForCurl(configData, 'formData')} \\
   -H "Content-Type: multipart/form-data"`;
  }
  return '';
};

export const getConfigCodeWithFormDataWithAuth = (
  codeLang,
  configData,
  chatflowId = '[CHATFLOW_ID]',
  apiKey = '[API_KEY]'
) => {
  if (codeLang === 'Python') {
    configData = unshiftFiles(configData);
    let fileType = configData[0].type;
    if (fileType.includes(',')) fileType = fileType.split(',')[0];
    return `import requests

API_URL = "${baseURL}/api/v1/prediction/${chatflowId}"
headers = {"Authorization": "Bearer ${apiKey}"}

# use form data to upload files
form_data = {
  "files": ${`('example${fileType}', open('example${fileType}', 'rb'))`}
}
body_data = {${getConfigExamplesForPython(configData, 'formData')}}

def query(form_data):
  response = requests.post(API_URL, headers=headers, files=form_data, data=body_data)
  return response.json()

output = query(form_data)
`;
  } else if (codeLang === 'JavaScript') {
    return `// use FormData to upload files
let formData = new FormData();
${getConfigExamplesForJS(configData, 'formData')}
async function query(formData) {
  const response = await fetch(
      "${baseURL}/api/v1/prediction/${chatflowId}",
      {
          headers: { Authorization: "Bearer ${apiKey}" },
          method: "POST",
          body: formData
      }
  );
  const result = await response.json();
  return result;
}

query(formData).then((response) => {
  console.log(response);
});
`;
  } else if (codeLang === 'cURL') {
    return `curl ${baseURL}/api/v1/prediction/${chatflowId} \\
   -X POST \\${getConfigExamplesForCurl(configData, 'formData')} \\
   -H "Content-Type: multipart/form-data" \\
   -H "Authorization: Bearer ${apiKey}"`;
  }
  return '';
};

export const getConfigCode = (codeLang, configData, chatflowId = '[CHATFLOW_ID]') => {
  if (codeLang === 'Python') {
    return `import requests

API_URL = "${baseURL}/api/v1/prediction/${chatflowId}"

def query(payload):
  response = requests.post(API_URL, json=payload)
  return response.json()

output = query({
  "question": "Hey, how are you?",
  "overrideConfig": {${getConfigExamplesForPython(configData, 'json')}
  }
})
`;
  } else if (codeLang === 'JavaScript') {
    return `async function query(data) {
  const response = await fetch(
      "${baseURL}/api/v1/prediction/${chatflowId}",
      {
          method: "POST",
          headers: {
              "Content-Type": "application/json"
          },
          body: JSON.stringify(data)
      }
  );
  const result = await response.json();
  return result;
}

query({
"question": "Hey, how are you?",
"overrideConfig": {${getConfigExamplesForJS(configData, 'json')}
}
}).then((response) => {
  console.log(response);
});
`;
  } else if (codeLang === 'cURL') {
    return `curl ${baseURL}/api/v1/prediction/${chatflowId} \\
   -X POST \\
   -d '{"question": "Hey, how are you?", "overrideConfig": {${getConfigExamplesForCurl(configData, 'json')}}' \\
   -H "Content-Type: application/json"`;
  }
  return '';
};

export const getConfigCodeWithAuthorization = (codeLang, configData, chatflowId = '[CHATFLOW_ID]', apiKey = '[API_KEY]') => {
  if (codeLang === 'Python') {
    return `import requests

API_URL = "${baseURL}/api/v1/prediction/${chatflowId}"
headers = {"Authorization": "Bearer ${apiKey}"}

def query(payload):
  response = requests.post(API_URL, headers=headers, json=payload)
  return response.json()

output = query({
  "question": "Hey, how are you?",
  "overrideConfig": {${getConfigExamplesForPython(configData, 'json')}
  }
})
`;
  } else if (codeLang === 'JavaScript') {
    return `async function query(data) {
  const response = await fetch(
      "${baseURL}/api/v1/prediction/${chatflowId}",
      {
          headers: {
              Authorization: "Bearer ${apiKey}",
              "Content-Type": "application/json"
          },
          method: "POST",
          body: JSON.stringify(data)
      }
  );
  const result = await response.json();
  return result;
}

query({
"question": "Hey, how are you?",
"overrideConfig": {${getConfigExamplesForJS(configData, 'json')}
}
}).then((response) => {
  console.log(response);
});
`;
  } else if (codeLang === 'cURL') {
    return `curl ${baseURL}/api/v1/prediction/${chatflowId} \\
   -X POST \\
   -d '{"question": "Hey, how are you?", "overrideConfig": {${getConfigExamplesForCurl(configData, 'json')}}' \\
   -H "Content-Type: application/json" \\
   -H "Authorization: Bearer ${apiKey}"`;
  }
  return '';
};

export const getMultiConfigCodeWithFormData = (codeLang) => {
  if (codeLang === 'Python') {
    return `body_data = {
  "openAIApiKey[chatOpenAI_0]": "sk-my-openai-1st-key",
  "openAIApiKey[openAIEmbeddings_0]": "sk-my-openai-2nd-key"
}`;
  } else if (codeLang === 'JavaScript') {
    return `formData.append("openAIApiKey[chatOpenAI_0]", "sk-my-openai-1st-key")
formData.append("openAIApiKey[openAIEmbeddings_0]", "sk-my-openai-2nd-key")`;
  } else if (codeLang === 'cURL') {
    return `-F "openAIApiKey[chatOpenAI_0]=sk-my-openai-1st-key" \\
-F "openAIApiKey[openAIEmbeddings_0]=sk-my-openai-2nd-key" \\`;
  }
};

export const getMultiConfigCode = () => {
  return `{
  "overrideConfig": {
      "openAIApiKey": {
          "chatOpenAI_0": "sk-my-openai-1st-key",
          "openAIEmbeddings_0": "sk-my-openai-2nd-key"
      }
  }
}`;
};

export const clearAllCredentials = (chatflow: any) => {
  const newChatflow = chatflow as ChatFlow;
  newChatflow.flowConfig = {
    ...chatflow.flowConfig,
    nodes: newChatflow.flowConfig.nodes.map((node) => {
      if (node.data.credential) {
        // remove credential from node
        delete node.data.credential;

        // remove credential from inputs
        node.data.inputs = {
          ...node.data.inputs,
          credential: null
        }
      }
      return node;
    }),
  };
  return newChatflow;

}