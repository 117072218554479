import { Add, Delete } from '@mui/icons-material';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { DataGrid, GridRowModel } from '@mui/x-data-grid';
import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const newPropertyItem = {
  "property": "",
  "description": "",
  "type": "",
  "required": false
}

type ToolPropertiesManagerProps = {
  name: string
  onPropertiesChange?: (properties) => void
}
export default function ToolPropertiesManager(props: ToolPropertiesManagerProps) {
  const { onPropertiesChange, name } = props
  const { setValue, getValues } = useFormContext();
  const { t } = useTranslation()
  const [properties, setProperties] = useState(getValues(name) || []);

  const handleOnAddClick = useCallback(async () => {
    setProperties([
      ...properties,
      {
        id: `p-id-${properties.length}`,
        key: `p-key-${properties.length}`,
        ...newPropertyItem
      }
    ]);
  }, [properties]);

  const handleOnDeleteClick = useCallback(async (id: string) => {
    const newProperties = properties.filter(item => item.id !== id);
    setProperties(newProperties);
  }, [properties]);

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    setProperties(properties.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  useEffect(() => {
    !!onPropertiesChange && onPropertiesChange(properties)
    setValue(name, properties);
  }, [properties])

  return (
    <Stack direction='column' gap={2}>
      <Typography variant='h4'>
        {t('tools.form.manage_properties_title')}
      </Typography>
      <Button
        startIcon={<Add />}
        variant='outlined'
        onClick={handleOnAddClick}
      >
        {t('tools.form.manage_properties_add_prop')}
      </Button>
      {
        properties.length > 0 && (
          <DataGrid
            rows={properties}
            disableRowSelectionOnClick={true}
            disableColumnFilter={true}
            disableColumnMenu={true}
            hideFooter={true}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={console.error}
            columns={[
              {
                field: 'property',
                headerName: t('tools.form.manage_properties_prop_name'),
                sortable: false,
                filterable: false,
                editable: true,
                flex: 1
              },
              {
                field: 'description',
                headerName: t('tools.form.manage_properties_prop_description'),
                sortable: false,
                filterable: false,
                editable: true,
                flex: 1
              },
              {
                field: 'type',
                headerName: t('tools.form.manage_properties_prop_type'),
                sortable: false,
                filterable: false,
                editable: true,
                width: 150,
                type: 'singleSelect',
                valueOptions: ['String', 'Number', 'Float', 'Boolean'],
              },
              {
                field: 'required',
                headerName: t('tools.form.manage_properties_prop_required'),
                sortable: false,
                filterable: false,
                width: 100,
                editable: true,
                type: 'boolean',
              },
              {
                field: 'actions',
                headerName: '',
                sortable: false,
                filterable: false,
                width: 40,
                renderCell({ row, tabIndex }) {
                  return <IconButton color='error' onClick={() => handleOnDeleteClick(row.id)}>
                    <Delete />
                  </IconButton>
                }
              },
            ]}
          />
        )
      }
    </Stack>
  )
}
