import { useLoadAllCredentials } from '@/hooks/credential.hooks';
import { Credential } from '@/models/Credential';
import { FilterProvider } from '@/typings';
import CredentialForm from '@/views/credentials/CredentialForm';
import { Add } from '@mui/icons-material';
import { Autocomplete, Box, Button, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DrawerLayer from '../drawer-layer/DrawerLayer';
import NodeAvatar from '../avatar/NodeAvatar';
import { t } from 'i18next';

type CredentialPickerProps = {
  value?: string;
  label?: string;
  disabled?: boolean;
  findKey?: string;
  onChange?: (credentialId: string, credential?: Credential) => void;
  formatValue?: (model: Credential) => any;
  filter?: FilterProvider<Credential>;
}
export default function CredentialPicker(props: CredentialPickerProps) {
  const { value, onChange, filter, formatValue, disabled, findKey = 'name' } = props;
  const filters = !!filter ? JSON.stringify(filter) : undefined;
  const { data: credentials, isLoading, refetch, remove: removeFromCache } = useLoadAllCredentials({ filters });
  const [choosed, setChoosed] = useState<Credential | null>(null);

  const handleOnCreateNewCredential = async (credential: Credential) => {
    removeFromCache();
    await refetch();
    setChoosed(credential);
  }

  useEffect(() => {
    if (!!choosed) {
      const returnValue = !!formatValue ? formatValue(choosed) : choosed.id;
      !!onChange && onChange(returnValue, choosed);
    }
  }, [choosed])

  useEffect(() => {
    if (!choosed && (credentials && credentials.length > 0) && value) {
      const c = credentials.find(x => x[findKey] === value);
      setChoosed(c);
    }
  }, [credentials, value, choosed]);

  useEffect(() => {
    return () => {
      removeFromCache();//remove cache when component is unmounted
    }
  }, []);

  return (
    <Stack direction='column' gap={1}>
      <Stack direction='row' alignItems='center'>
        <Box flexGrow={1}>
          {!!choosed && (
            <Stack direction='row' alignItems='center' gap={2}>
              <NodeAvatar iconUrl={choosed.icon} size={30} iconColor='primary.main' bgcolor='background.default' />
              <Typography variant='label'>{choosed.name}</Typography>
            </Stack>
          )}
        </Box>
        <DrawerLayer
          content={(openDrawer) => (
            <Button
              variant='outlined'
              size='small'
              color='secondary'
              property='action'
              onClick={openDrawer}
              startIcon={<Add />}
            >
              {t('credentials.add_button')}
            </Button>
          )}
          as={CredentialForm}
          onClose={handleOnCreateNewCredential}
        />
      </Stack>
      <Box>
        <Autocomplete
          disableClearable={true}
          options={credentials || []}
          loading={isLoading}
          value={choosed}
          disabled={disabled}
          onChange={(e, selection) => {
            setChoosed(selection)
          }}
          getOptionKey={option => option.id}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => <TextField {...params} placeholder={t('credentials.picker.input_label')} />}
          renderOption={(props, option) => (
            <Box component='li' {...props}>
              <NodeAvatar iconUrl={option.icon} />
              <Typography ml={1} variant='h5'>{option.name}</Typography>
            </Box>
          )}
        />
      </Box>
    </Stack>
  )
}
