import { Box, Typography } from '@mui/material'
import React, { ReactNode } from 'react'

export enum IllustrationsCodes {
  default = 'default',
  error = 'error',
  empty = 'empty',
  emptyApp = 'empty-apps',
  emptyAssistant = 'empty-assistant',
  emptyCredential = 'empty-credentials',
  upgradePlan = 'upgrade-plan',
}

import defaultIllustration from '@/assets/illustrations/default.svg'
import errorIllustration from '@/assets/illustrations/error.svg'
import emptyDataIllustration from '@/assets/illustrations/empty-data.svg'
import emptyAppsIllustration from '@/assets/illustrations/empty-data-apps.svg'
import emptyAssistantsIllustration from '@/assets/illustrations/empty-assistant.svg'
import emptyCredentialsIllustration from '@/assets/illustrations/empty-credentials.svg'
import upgradePlanIllustration from '@/assets/illustrations/upgrade-plan.svg'

const illustrations = {
  [IllustrationsCodes.default]: {
    path: defaultIllustration,
    alt: 'Default Information'
  },
  [IllustrationsCodes.error]: {
    path: errorIllustration,
    alt: 'System of Down'
  },
  [IllustrationsCodes.empty]: {
    path: emptyDataIllustration,
    alt: 'Empty Data'
  },
  [IllustrationsCodes.emptyApp]: {
    path: emptyAppsIllustration,
    alt: 'Empty Apps'
  },
  [IllustrationsCodes.emptyAssistant]: {
    path: emptyAssistantsIllustration,
    alt: 'Empty Assistant'
  },
  [IllustrationsCodes.emptyCredential]: {
    path: emptyCredentialsIllustration,
    alt: 'Empty Data Credentials'
  },
  [IllustrationsCodes.upgradePlan]: {
    path: upgradePlanIllustration,
    alt: 'Upgrade Plan Illustration'
  },
}

type IllustrationProps = {
  code: IllustrationsCodes;
  size?: 'small' | 'medium' | 'large';
  title?: string
  subtitle?: string;
  content?: ReactNode
  contentPosition?: 'after-illustration' | 'before-illustration'
};

const sizes = {
  small: { width: 300 },
  medium: { width: 400 },
  large: { width: 700 },
}

export default function Illustration(props: IllustrationProps) {
  let { code, size = 'medium', title, subtitle, content, contentPosition = 'after-illustration' } = props
  const { width } = sizes[size];

  if (!(code in illustrations)) {
    code = IllustrationsCodes.default;
  }
  const { path, alt } = illustrations[code];

  const contentSx = {
    maxWidth: width,
    width: '100%',
    display:'flex',
    justifyContent:'center',
    mb: 2
  };

  return (
    <Box
      component='div'
      display='flex'
      flexDirection='column'
      justifyContent='space-between'
      alignItems={'center'}
      minHeight={600}
    >
      <Box component={'div'} gap={2} textAlign='center'>
        {!!title && (
          <Typography variant='h2' sx={{ mb: 2 }}>{title}</Typography>
        )}
        {!!subtitle && (
          <Typography variant='subtitle1' sx={{ mb: 2 }}>{subtitle}</Typography>
        )}
      </Box>
      {!!content && contentPosition === 'before-illustration' && (
        <Box
          component='div'
          sx={contentSx}
        >
          {content}
        </Box>
      )}
      <Box
        component='img'
        src={path}
        alt={alt}
        sx={{
          maxWidth: width,
          width: '100%',
          mb: 2
        }}
      />
      {!!content && contentPosition === 'after-illustration' && (
        <Box
          component='div'
          sx={contentSx}
        >
          {content}
        </Box>
      )}
    </Box>
  )
}
