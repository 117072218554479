import { Grid } from '@mui/material';
import React, { ReactNode } from 'react'

type GridViewSkeletonProps = {
  count?: number,
  gridSpacing?: number,
  renderItem: (item, loading: boolean) => ReactNode
}

export default function GridViewSkeleton(props: GridViewSkeletonProps) {
  const { count = 5, gridSpacing, renderItem } = props;
  const list = Array.from(Array(count).keys());
  return (
    <Grid container spacing={gridSpacing}>
      {list.map((_, index) => (
        <Grid key={`grid-view-item-${index}}`} item
          lg={3}
          md={4}
          sm={6}
          xs={12}
        >
          {renderItem({}, true)}
        </Grid>
      ))}
    </Grid>
  )
}
