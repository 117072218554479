import { useMutation, useQuery } from "react-query"
import client from "../api/client";
import { Variable } from '@/models/Variable';
import queryClient from "@/utils/query-client";
import AppSettings from "@/config/main";
import { delayMs } from "@/utils/timer";

export enum VariableRequests {
  all = 'variables.all',
  pagination = 'variables.all.pagination',
  detail = 'detail'
}

export const useLoadAllVariables = () => {
  return useQuery<Variable[], Error>(VariableRequests.all, async () => {
    const { data } = await client.get("/variables");
    await delayMs(AppSettings.defaultRequestDelay);
    return data.map(Variable.load)
  });
}

export const useVariableById = (id: string) => {
  return useQuery<Variable, Error>(VariableRequests.detail, async () => {
    const { data } = await client.get(`/variables/${id}`);
    await delayMs(AppSettings.defaultRequestDelay);
    return Variable.load(data)
  });
}

export const useCreateVariableMutation = () => {
  return useMutation({
    async mutationFn(variable: Variable) {
      const { data } = await client.post('/variables', variable);
      return Variable.load(data);
    },
    onSuccess() {
      queryClient.invalidateQueries(VariableRequests.all)
      queryClient.invalidateQueries(VariableRequests.pagination)
    },
    retry: 3
  });
}

type UpdateVariableParam = { id: string, data: Variable };
export const useUpdateVariableMutation = () => {
  return useMutation({
    async mutationFn({ id, data }: UpdateVariableParam) {
      const response = await client.put(`/variables/${id}`, data);
      return Variable.load(response.data);
    },
    onSuccess() {
      queryClient.invalidateQueries(VariableRequests.all)
      queryClient.invalidateQueries(VariableRequests.pagination)
    }
  });
}

export const useDeleteVariableMutation = () => {
  return useMutation({
    async mutationFn(id: string) {
      const result = await client.delete(`/variables/${id}`);
      return result
    },
    onSuccess() {
      queryClient.invalidateQueries(VariableRequests.all)
      queryClient.invalidateQueries(VariableRequests.pagination)
    }
  });
}