import React, { useEffect, useMemo, useState } from 'react'
import { PropsWithFormItem } from '@/ui-component/form/form-item.types';
import FormItemCustom from '@/ui-component/form/FormItemCustom';
import { Alert, Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { Close, UploadFile } from '@mui/icons-material';
import { convertFileIntoFileInputValue, convertRawToFileInputValue, FileInputValue } from './utils';
import { IconFile } from '@tabler/icons';
import PerfectScrollbar from 'react-perfect-scrollbar';

import './file-input.scss'

type FileInputProps = {
  fileType: string;
  limit?: number;
  valueFormatter?: (fileInputs: FileInputValue[]) => any;
  onChange?: (valueFormatted: any) => void
  value?: string | string[]
}
export default function FileInput(props: PropsWithFormItem<FileInputProps>) {
  const { name, label, fileType, onChange, value, helperText, limit = 1, valueFormatter } = props;
  const [fileInputs, setInputs] = useState<FileInputValue[]>([]);
  const multiple = limit > 1;
  const limitReached = useMemo(() => fileInputs.length >= limit, [fileInputs]);

  const handleUploadClick = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.multiple = multiple;
    fileInput.accept = fileType;

    fileInput.addEventListener('change', async (e: Event) => {
      const files = (e.target as HTMLInputElement).files;
      const listToAdd: FileInputValue[] = [];

      for (let index = 0; index < files.length; index++) {
        const futureTotal = fileInputs.length + listToAdd.length + 1;// +1 because it will add 1 in the current loop
        if (futureTotal <= limit) {
          const file = files.item(index);
          const fileInputValue = await convertFileIntoFileInputValue(file);
          listToAdd.push(fileInputValue);
        }
      }

      if (multiple) {
        const newfileAsString = [...fileInputs, ...listToAdd];
        setInputs(newfileAsString);
      } else {
        const item = listToAdd[0];
        setInputs([item]);
      }
    });
    fileInput.click();
  }

  const handleOnRemove = (indexToRemove: number) => {
    fileInputs.splice(indexToRemove, 1)
    const newfileInputs = [
      ...fileInputs
    ];
    setInputs(newfileInputs);
  }

  useEffect(() => {
    if (!!onChange) {
      const _value = !!valueFormatter ? valueFormatter(fileInputs) : fileInputs;
      onChange(_value);
    }
  }, [fileInputs]);

  useEffect(() => {
    if (!!value && fileInputs.length === 0) {
      const loadValue = async () => {
        const filesInputValues: FileInputValue[] = [];

        if (Array.isArray(value)) {
          for (const rawBase64PLusFilename of value) {
            const inputValue = await convertRawToFileInputValue(rawBase64PLusFilename);
            filesInputValues.push(inputValue)
          }
          setInputs(filesInputValues);
          return;
        }

        if (typeof value === 'string') {
          if (value.startsWith('[') && value.endsWith(']')) {
            const jsonValue = JSON.parse(value) as string[];
            for (const rawBase64PLusFilename of jsonValue) {
              const inputValue = await convertRawToFileInputValue(rawBase64PLusFilename);
              filesInputValues.push(inputValue)
            }
            setInputs(filesInputValues);
            return;
          }
          else {
            const inputValue = await convertRawToFileInputValue(value);
            setInputs([inputValue]);
            return;
          }
        }
      }
      loadValue();
    }
  }, []);

  return (
    <FormItemCustom
      name={name}
      label={label}
      helperText={helperText}
      component={(
        <Stack direction={'column'} gap={1}>
          {multiple && limitReached && <Alert variant='filled' color='warning' closeText='close' >You reached the limit</Alert>}
          <Button
            variant='outlined'
            color='secondary'
            disabled={limitReached}
            onClick={handleUploadClick}
            startIcon={<UploadFile />}
          >
            {multiple ? 'Choose your Files' : 'Choose your File'}
          </Button>
          <Box component={PerfectScrollbar} style={{ height: '100%', maxHeight: 300 }}>
            <Stack direction='row' gap={2} className='file-input-preview-container' flexWrap='wrap'>
              {fileInputs.map((fileInput, index) => (
                <Box key={`file-input-preview-${index}`} component='div' className='file-input-preview-item'>
                  <IconButton size='small' color='secondary' property='delete' className='file-input-preview-item-box-remove' onClick={() => handleOnRemove(index)}>
                    <Close fontSize='small' />
                  </IconButton>
                  <Box component='div' className='file-input-preview-item-box'>
                    <IconFile className='file-input-preview-item-box-icon' size={50} />
                  </Box>
                  <Typography variant='body1' className='file-input-preview-item-filename'>
                    {fileInput.filename}
                  </Typography>
                </Box>
              ))}
            </Stack>
          </Box>
        </Stack>
      )}
    />
  )
}
