import React from 'react';
import { useFormContext } from 'react-hook-form';
import CodeMirror from '@uiw/react-codemirror'
import { javascript, javascriptLanguage } from '@codemirror/lang-javascript'
import { vscodeDark } from '@uiw/codemirror-theme-vscode'
import { EditorView } from '@codemirror/view'
import { autocompletion, CompletionContext } from "@codemirror/autocomplete"
import { useLoadAllVariables } from '@/hooks/variables.hooks';

const customStyle = EditorView.baseTheme({
  '&': {
    color: '#191b1f',
    padding: '10px'
  },
  '.cm-placeholder': {
    color: 'rgba(120, 120, 120, 0.5)'
  }
})

export default function ToolCodeEditor({ name }) {
  const { setValue, getValues } = useFormContext();
  const code = getValues(name);
  const { data: variables } = useLoadAllVariables();

  const variablesCompletions = (context: CompletionContext) => {
    let word = context.matchBefore(/\w*/)
    if (word.from == word.to && !context.explicit)
      return null

    const variablesCompletions = variables.map(variable => ({
      label: `$vars.${variable.name}`,
      type: 'variable',
      detail: variable.type === 'static' ? variable.value : '- runtime var -'
    }))

    return {
      from: word.from,
      options: variablesCompletions
    }
  }

  const jsDocCompletions = javascriptLanguage.data.of({
    autocomplete: variablesCompletions,
  });

  return (
    <CodeMirror
      value={code || ''}
      height={'300px'}
      theme={vscodeDark}
      extensions={[
        javascript({
          jsx: false,
        }),
        autocompletion(),
        jsDocCompletions,
        EditorView.lineWrapping,
        customStyle
      ]}
      onChange={(code) => setValue(name, code)}
      editable={true}
      basicSetup={{
        autocompletion: true,
        lineNumbers: true
      }}
    />
  )
}
