import { Assistant } from '@/models/Assistant';
import { Card, CardHeader, Avatar, CardContent, Typography, CardActions, Stack, IconButton, Divider } from '@mui/material';
import React from 'react'
import CardSkeleton from '../skeletons/CardSkeleton';
import { Delete, Edit } from '@mui/icons-material';
import LabelValue from '../label-value/LabelValue';
import DrawerLayer from '../drawer-layer/DrawerLayer';
import AssistantForm from '../../views/assistants/AssistantForm';
import ReadMore from '../read-mode/ReadMore';

type AssistantCardProps = {
  isLoading?: boolean;
  assistant: Assistant;
  onDeleteClick?: (assistant: Assistant) => void
}
export default function AssistantCard(props: AssistantCardProps) {
  const { assistant, isLoading, onDeleteClick } = props;

  if (isLoading) {
    return <CardSkeleton />;
  }

  return (
    <Card variant="outlined">
      <CardHeader
        avatar={
          <Avatar src={assistant.iconSrc} sx={{ width: 40, height: 40 }}>
            {assistant.detailsData.name.charAt(0).toUpperCase()}
          </Avatar>
        }
        title={assistant.detailsData.name}
        titleTypographyProps={{
          title: assistant.detailsData.name,
        }}
      />
      <CardContent>
        <Typography
          variant="body1"
          sx={{
            overflowWrap: 'break-word',
            whiteSpace: 'pre-line',
          }}
        >
          <ReadMore
            content={assistant.detailsData.description}
            limit={100}
          />
        </Typography>
        <Divider sx={{ my: 2 }} />
        <LabelValue sxContainer={{ mb: 2 }} direction='column' label='OpenAI ID' value={assistant.detailsData.id} />
        <LabelValue sxContainer={{ mb: 2 }} direction='column' label='Instructions' value={assistant.detailsData.instructions} />
        <LabelValue sxContainer={{ mb: 2 }} direction='column' label='Model' value={assistant.detailsData.model} />
      </CardContent>
      <CardActions>
        <Stack direction='row' justifyContent={'flex-end'} width='100%'>
          <DrawerLayer
            content={(open) => (
              <IconButton color='secondary' property='action' onClick={open}>
                <Edit />
              </IconButton>
            )}
            as={AssistantForm}
            assistant={assistant}
          />
          {!!onDeleteClick && (
            <IconButton color='secondary' property='delete' onClick={() => onDeleteClick(assistant)}>
              <Delete />
            </IconButton>
          )}
        </Stack>
      </CardActions>
    </Card>
  );
}
