import { Avatar, Badge, styled, SxProps } from '@mui/material';
import React, { ReactNode } from 'react';

type CombinedAvatarsProps = {
  baseAvatar: ReactNode | string
  combined: ReactNode | string
  sxBaseAvatar?: SxProps
  sxCombinedAvatar?: SxProps
}

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 22,
  height: 22,
  border: `2px solid ${theme.palette.background.paper}`,
}));

export default function CombinedAvatars(props: CombinedAvatarsProps) {
  const { baseAvatar, combined, sxBaseAvatar, sxCombinedAvatar } = props
  return (
    <Badge
      overlap="circular"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      badgeContent={
        <SmallAvatar src={typeof combined === 'string' ? combined : undefined} sx={sxCombinedAvatar} >
          {typeof combined !== 'string' && combined}
        </SmallAvatar>
      }
    >
      <Avatar src={typeof baseAvatar === 'string' ? baseAvatar : undefined} sx={sxBaseAvatar}>
        {typeof baseAvatar !== 'string' && baseAvatar}
      </Avatar>
    </Badge>
  )
}
