import { Autocomplete, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';

import './input-tags.scss';

type InputTagsProps = {
  mode?: 'edit' | 'view';
  value?: string[] | string;
  resultFormatter?: (tags: string[]) => any;
  valueFormatter?: (value: any) => string[]
  onChange?: (tags: string[] | any) => void
}

export default function InputTags(props: InputTagsProps) {
  const { mode = 'view', value, onChange, resultFormatter, valueFormatter } = props;
  const [internalValue, setInternalValue] = useState<string[]>([]);
  const handleOnChange = (_e, newValue: string[]) => {
    const result = !!resultFormatter ? resultFormatter(newValue) : newValue;
    !!onChange && onChange(result);
    setInternalValue(newValue);
  }

  useEffect(() => {
    const _internalValue = !!valueFormatter ? valueFormatter(value) : value as string[];
    setInternalValue(_internalValue);
  }, []);

  return <Autocomplete
    multiple
    freeSolo
    disabled={mode === 'view'}
    selectOnFocus
    clearOnBlur
    handleHomeEndKeys
    value={internalValue}
    options={[]}
    onChange={handleOnChange}
    renderOption={(props, option) => <li {...props}>{option}</li>}
    renderInput={(params) => (
      <TextField {...params} />
    )}
  />
}
