import { SectionInjector } from '@/ui-component/section/Section';
import { Box } from '@mui/material';
import React from 'react';
import LanguageSelector from './LanguageSelector/LanguageSelector';

type HeaderProps = {
}
const Header = ({ }: HeaderProps) => {
  return (
    <Box
      component='div'
      className='main-layout-header-container'
    >
      <Box component='div' className='main-layout-header-inner'>
        <Box component='div' className='main-layout-header-dynamic-content'>
          <SectionInjector code='header-content' />
        </Box>
        <LanguageSelector />
      </Box>
    </Box>
  )
}

export default Header
