import { CircularLoading } from '@/ui-component/loading/Loader'
import React from 'react'
import { Outlet } from 'react-router-dom'

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => (
    <React.Suspense fallback={<CircularLoading center />}>
        <Outlet />
    </React.Suspense>
)

export default MinimalLayout
