import { ButtonGroup, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { IconLayoutGrid, IconList, IconTable } from '@tabler/icons';
import React, { useEffect, useState } from 'react';
import { DisplayViewTypes, DisplayViewTypesEnum } from './constants';
import { getStorageViewType, setStorageViewType } from './utils';

type DisplayViewProps = {
  /**
   * Code used to identify the config stored
   * @example 'display-view-page-variable'
   */
  code: string;

  /**
   * The default view type when the config is not set
   * 
   * @example 'grid' or 'list' 
   * @type DisplayViewTypes
   */
  defaultViewType: DisplayViewTypes,

  /**
   * The allowed list of types to be rendered
   * @example ['table', 'list', 'grid']
   * @type DisplayViewTypes[]
   */
  allowedViewTypes?: DisplayViewTypes[],

  /**
   * Function wrapper that will change when display view changes.
   * 
   * @param toggle The toggle component to triger the change of view type
   * @param viewType the actual view type to be rendered
   * @returns The wrapped React component to be rendered on the screen
   */
  children: (toggle: React.ReactNode, viewType: DisplayViewTypes) => React.ReactNode
}

export default function DisplayView(props: DisplayViewProps) {
  const { code, defaultViewType, allowedViewTypes = ['grid', 'table'], children } = props
  const [viewType, setViewType] = useState<DisplayViewTypes>(getStorageViewType(code) || defaultViewType);

  useEffect(() => {
    setStorageViewType(code, viewType);
  }, []);

  const handleOnChangeViewType = (_evt: any, nextView: DisplayViewTypes) => {
    if (!!nextView) {
      setStorageViewType(code, nextView);
      setViewType(nextView);
    }
  }

  const toggle = (
    <ButtonGroup
      disableElevation
      variant='contained'
      aria-label='outlined primary button group'
    >
      <ToggleButtonGroup
        exclusive
        sx={{ maxHeight: 40 }}
        value={viewType}
        color='primary'
        onChange={handleOnChangeViewType}
      >
        {
          allowedViewTypes.includes('grid') && (
            <ToggleButton
              value={DisplayViewTypesEnum.grid}
              title='Card View'
            >
              <IconLayoutGrid />
            </ToggleButton>
          )
        }
        {
          allowedViewTypes.includes('table') && (
            <ToggleButton
              value={DisplayViewTypesEnum.table}
              title='Table View'
            >
              <IconTable />
            </ToggleButton>
          )
        }
        {
          allowedViewTypes.includes('list') && (
            <ToggleButton
              value={DisplayViewTypesEnum.list}
              title='List View'
            >
              <IconList />
            </ToggleButton>
          )
        }
      </ToggleButtonGroup>
    </ButtonGroup>
  )

  return (
    <>
      {children(toggle, viewType)}
    </>
  )
}
