import React from 'react';
import { QueryClientProvider } from 'react-query';
// import { ReactQueryDevtools } from 'react-query/devtools';
import { ThemeProvider } from '@mui/material';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import Routes from '@/routes';
import createGaiaTheme from '@/themes';
import { firebaseAnalytics } from '@/utils/firebase'
import { logEvent } from 'firebase/analytics';
import { AppContextProvider } from './context/app.context';
import queryClient from '@/utils/query-client';
import { ContextualDrawerProvider } from './context/contextual-drawer.context';
import { FlagsContextProvider } from './context/flags.context';
import AppSettings from './config/main';

const theme = createGaiaTheme();

const App = () => {
  logEvent(firebaseAnalytics, 'pageView');
  return (
    <QueryClientProvider client={queryClient} >
      <FlagsContextProvider defaults={AppSettings.defaultFlags}>
        <AppContextProvider>
          <StyledEngineProvider injectFirst >
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <ContextualDrawerProvider>
                <Routes />
              </ContextualDrawerProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </AppContextProvider>
      </FlagsContextProvider>
    </QueryClientProvider>
  );
};

export default App;
