import { useQuery } from 'react-query'
import client from '@/api/client'
import { Subscription } from '../models/Subscription';
import AppSettings from '@/config/main';
import { delayMs } from '@/utils/timer';

export enum SubscriptionRequests {
  all = 'subscription.all',
  current = 'subscription.current'
}

export const useMySubscription = (options?: any) => {
  return useQuery<Subscription, Error>(SubscriptionRequests.current, async () => {
    const { data } = await client.get('/load-my-subscription');
    await delayMs(AppSettings.priorityRequestDelay);
    return Subscription.load(data)
  }, {
    ...options,
    initialData: null,
    refetchOnWindowFocus: false,
    refetchInterval: 1000 * 60 * 60
  });
}

export const useAllMySubscriptions = () => {
  return useQuery<Subscription[], Error>(SubscriptionRequests.all, async () => {
    const { data } = await client.get('/load-all-subscriptions');
    await delayMs(AppSettings.priorityRequestDelay);
    return data.map(Subscription.load)
  }, {
    initialData: [],
  });
}