import React from 'react'
import { LinearProgress, CircularProgress } from '@mui/material'
import { styled } from '@mui/material/styles'

// // styles
// const LoaderWrapper = styled('div')({
//     position: 'fixed',
//     top: 0,
//     left: 0,
//     zIndex: 1301,
//     width: '100%'
// })

const CenterPlacement = styled('div')({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%'
})

// ==============================|| LOADER ||============================== //
const Loader = () => (
    <LinearProgress color='primary' />
)

type CircularLoadingProps = {
    center?: boolean
}
export const CircularLoading = (props: CircularLoadingProps) => {
    const { center = true } = props
    if (center) {
        return (
            <CenterPlacement>
                <CircularProgress color='primary' />
            </CenterPlacement>
        )
    }
    else {
        return (
            <CircularProgress color='primary' />
        )
    }
}

export default Loader
