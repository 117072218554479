import React from 'react'
import { useDispatch } from 'react-redux'
import { enqueueSnackbar as enqueueSnackbarAction, closeSnackbar as closeSnackbarAction } from '@/store/actions'
import { Button } from '@mui/material'
import { IconX } from '@tabler/icons'
import { AxiosError } from 'axios'
import { t } from 'i18next'

export function useSnackbarNotification() {
  const dispatch = useDispatch()

  const enqueueSnackbar = (...args: any) => dispatch(enqueueSnackbarAction.apply(null, args))
  const closeSnackbar = (...args: any) => dispatch(closeSnackbarAction.apply(null, args))

  const sucessSnackbar = (message: string) => {
    enqueueSnackbar({
      message,
      options: {
        key: new Date().getTime() + Math.random(),
        variant: 'success',
        action: (key: any) => {
          return (
            <Button style={{ color: 'white' }} onClick={() => closeSnackbar(key)
            }>
              <IconX />
            </Button>
          )
        }
      }
    });
  }

  const errorSnackbar = (message: string) => {
    enqueueSnackbar({
      message,
      options: {
        key: new Date().getTime() + Math.random(),
        variant: 'error',
        persist: true,
        action: (key: any) => (
          <Button style={{ color: 'white' }} onClick={() => closeSnackbar(key)}>
            <IconX />
          </Button>
        )
      }
    });
  }

  const errorResponseSnackbar = (error: AxiosError) => {
    const { message, response: { data } } = error;
    const msg = data || message || t('generic_error_message', 'Something goes wrong!');

    enqueueSnackbar({
      message: msg,
      options: {
        key: new Date().getTime() + Math.random(),
        variant: 'error',
        persist: false,
      }
    });
  }

  return { enqueueSnackbar, closeSnackbar, sucessSnackbar, errorSnackbar, errorResponseSnackbar }
}