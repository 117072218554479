import { Box } from '@mui/material'
import cx from 'classnames'
import React from 'react'
import { Outlet } from 'react-router-dom'
import Header from './Header'
import MainContent from './MainContent'
import Sidebar from './Sidebar'
import './main-layout.scss'
import { useAppContext } from '@/context/app.context'
import Loader from '@/ui-component/loading/Loader'

type MainLayoutProps = {
    extended?: boolean
}

const MainLayout = ({ extended }: MainLayoutProps) => {
    const { isSidebarOpen, smallScreen, toggleSidebar } = useAppContext();
    return (
        <Box
            component='main'
            className={'main-layout-root'}
        >
            <Sidebar
                isOpen={isSidebarOpen}
                className={cx('main-sidebar-container', {
                    'sidebar-closed': !isSidebarOpen,
                    'sidebar-container-mobile': smallScreen
                })}
            />
            <Box
                component='div'
                className={cx('main-content-container', {
                    'main-content-container-mobile': smallScreen,
                    'main-content-overlay-visible': smallScreen && isSidebarOpen
                })}
            >
                <Header />
                <React.Suspense fallback={<Loader />}>
                    <Box
                        component={extended ? 'div' : MainContent}
                    >
                        <Outlet />
                    </Box>
                </React.Suspense>
                <Box component='div' className='main-content-overlay' onClick={toggleSidebar} />
            </Box>
        </Box>
    )
}

export default MainLayout
