import React from 'react'
import Illustration, { IllustrationsCodes } from '@/ui-component/illustrations/Illustration';
import { Button } from '@mui/material';
import newrelicInstance from '@/utils/newrelic';

export type ErrorBoundaryState = {
  hasError: boolean;
  errorCount: number;
}

export type ErrorBoundaryProps = {
  children: React.ReactNode
}
class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorCount: 0
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
    newrelicInstance.noticeError(error);
  }

  render() {
    const { hasError } = this.state
    const { children } = this.props;

    if (hasError) {
      return (
        <Illustration
          title='Something went wrong'
          subtitle='Our team is already aware of the situation'
          code={IllustrationsCodes.error}
          content={(
            <Button
              variant='contained'
              onClick={() => document.location.reload()}
            >
              Try to reload the page
            </Button>
          )}
        />
      );
    }
    return children;
  }
}

export default ErrorBoundary