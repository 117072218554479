import { Box } from '@mui/material'
import React, { PropsWithChildren } from 'react'

export default function Form({ children }: PropsWithChildren) {
  return (
    <Box
      component={'form'}
      autoComplete='off'
      minWidth={'100%'}
      display={'flex'}
      flexDirection={'column'}
      gap={2}
    >
      {children}
    </Box>
  )
}

export { default as FormItem } from './FormItem'
export { default as FormItemCustom } from './FormItemCustom'
export { default as FormItemIconUpload } from './FormItemIconUpload'
export { default as FormItemSwitch } from './FormItemSwitch'
