import React, { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { fetchAndActivate, getAll } from 'firebase/remote-config';
import Cookies from 'js-cookie';

type FlagValueType = string | number | boolean
type IFlagContext = {
  flags: Record<string, FlagValueType>,
  isFlagEnabled: (flagKey: string) => boolean;
  isValueEqual: (value: any) => boolean
};

export const FlagsContext = createContext<IFlagContext | null>(null);

import { firebaseRemoteConfig } from '@/utils/firebase'

type FlagsContextProviderProps = {
  defaults: any
}

function normalizeToFeaturesCookies(values: Record<string, FlagValueType>) {
  const result = [];
  Object.keys(values).forEach(k => {
    const value = values[k];
    const type = (typeof value).charAt(0);
    const name = k;
    const t = `${name}.${value}.${type}`;
    result.push(t);
  })
  return result.join('-');
}

export const FlagsContextProvider = ({ children, defaults }: PropsWithChildren<FlagsContextProviderProps>) => {
  const [flags, setFlags] = useState(defaults);

  const loadFlags = async () => {
    try {
      await fetchAndActivate(firebaseRemoteConfig);
      const allConfigs = await getAll(firebaseRemoteConfig);
      const allFlags: Record<string, FlagValueType> = {};
      Object.keys(allConfigs).forEach(k => {
        const value = allConfigs[k];
        if (typeof defaults[k] === 'string') {
          allFlags[k] = value.asString()
        } else if (typeof defaults[k] === 'number') {
          allFlags[k] = value.asNumber();
        } else if (typeof defaults[k] === 'boolean') {
          allFlags[k] = value.asBoolean();
        } else {
          allFlags[k] = value.asString()
        }
      });
      setFlags(allFlags);
    } catch (error) {
      console.log(error)
    }
  }

  const isFlagEnabled = useCallback((flagKey) => {
    if (flagKey in flags) {
      return flags[flagKey] === true;
    }
    return false;
  }, [flags]);

  const isValueEqual = useCallback((key: string, value: any) => {
    if (key in flags) {
      return flags[key] === value;
    }
    return false;
  }, [flags]);

  useEffect(() => {
    const cookieValue = normalizeToFeaturesCookies(flags);
    Cookies.set('__features', cookieValue);
  }, [flags]);

  useEffect(() => {
    firebaseRemoteConfig.defaultConfig = defaults;
    loadFlags();
  }, []);


  const context = useMemo(() => ({
    ...flags,
    isFlagEnabled,
    isValueEqual
  }), [flags])

  return (
    <FlagsContext.Provider value={context}>
      {children}
    </FlagsContext.Provider>
  )
}

export function useFlagsContext() {
  if (!FlagsContext) {
    throw new Error('Context not provided!')
  }
  return useContext(FlagsContext);
}
