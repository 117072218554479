export function slugify(str, spaceCharReplace = '_') {
  return String(str)
    .normalize('NFKD') // split accented characters into their base characters and diacritical marks
    .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
    .trim() // trim leading or trailing whitespace
    .toLowerCase() // convert to lowercase
    .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
    .replace(/\s+/g, spaceCharReplace) // replace spaces with hyphens
    .replace(/-+/g, spaceCharReplace); // remove consecutive hyphens
}

export function removerSpacesAndSpecialCharacters(str: string): string {
  str = str.trim();// Remove espaços no início e no final
  str = str.replace(/\s+/g, ' ');// Substituir múltiplos espaços por um único espaço
  str = str.replace(/[^a-zA-Z0-9 ]/g, '');// Remover caracteres especiais
  return str;
}