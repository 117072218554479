import { useMutation, useQuery } from "react-query"
import client from "@/api/client";
import { ChatFlow } from '@/models/ChatFlow';
import queryClient from "@/utils/query-client";
import { delayMs } from "@/utils/timer";
import AppSettings from "@/config/main";
import { ChatMessage } from "@/models/ChatMessage";
import { ChatbotMessage } from "@/typings";

export enum ChatflowRequests {
  all = 'chatflow.all',
  pagination = 'chatflow.pagination',
  detail = 'chatflow.detail',
  streaming = 'chatflow.streaming',
  internalMessages = 'chatflow.internal-messages'
}

export const useLoadAllChatflows = () => {
  return useQuery<ChatFlow[], Error>(ChatflowRequests.all, async () => {
    const { data } = await client.get("/chatflows");
    await delayMs(AppSettings.defaultRequestDelay);
    return data.map(ChatFlow.load)
  });
}

export const useLoadChatflowDetail = (id: string, instantQuery = false) => {
  return useQuery<ChatFlow, Error>(ChatflowRequests.detail, async () => {
    const { data } = await client.get(`/chatflows/${id}`);
    await delayMs(AppSettings.defaultRequestDelay);
    return ChatFlow.load(data)
  }, {
    enabled: instantQuery
  });
}

export const useLoadChatflowIsStreaming = (id: string) => {
  return useQuery<boolean, Error>(ChatflowRequests.streaming, async () => {
    const { data: { isStreaming } } = await client.get(`/chatflows-streaming/${id}`);
    return Boolean(isStreaming);
  });
}

export const useLoadChatflowInternalMessages = (id: string) => {
  return useQuery<ChatMessage[], Error>(ChatflowRequests.internalMessages, async () => {
    const { data } = await client.get(`/internal-chatmessage/${id}`);
    return data.map(ChatMessage.load);
  });
}

export const useCreateChatflowMutation = () => {
  return useMutation({
    async mutationFn(chatflow: ChatFlow) {
      const formData = chatflow.toFormData();
      const { data } = await client.post('/chatflows', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return ChatFlow.load(data)
    },
    onSuccess() {
      queryClient.invalidateQueries(ChatflowRequests.all)
      queryClient.invalidateQueries(ChatflowRequests.pagination)

    },
  });
}

export const useUpdateChatflowMutation = () => {
  return useMutation({
    async mutationFn(chatflow: ChatFlow) {
      const formData = chatflow.toFormData();
      const result = await client.put(`/chatflows/${chatflow.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return ChatFlow.load(result.data);
    },
    onSuccess() {
      queryClient.invalidateQueries(ChatflowRequests.all)
      queryClient.invalidateQueries(ChatflowRequests.pagination)
    }
  });
}

export const useUpdateChatflowPropertyMutation = () => {
  return useMutation({
    async mutationFn({ id, data }: { id: string, data: any }) {
      const fd = new FormData();
      Object.keys(data).forEach(k => fd.append(k, data[k]))
      const result = await client.put(`/chatflows/${id}`, fd);
      return ChatFlow.load(result.data);
    }
  });
}

export const useDeleteChatflowMutation = () => {
  return useMutation({
    async mutationFn(id: string) {
      const result = await client.delete(`/chatflows/${id}`);
      return result
    },
    onSuccess() {
      queryClient.invalidateQueries(ChatflowRequests.all)
      queryClient.invalidateQueries(ChatflowRequests.pagination)
    }
  });
}

export const useGenerateChatflowMutation = () => {
  return useMutation({
    async mutationFn(data: FormData) {
      const result = await client.post(`/chatflows-generate/`, data);
      return result
    },
    onSuccess() {
      queryClient.invalidateQueries(ChatflowRequests.all)
    }
  });
}

type SendChatflowInternalMessagePredictionParams = {
  chatflowId: string,
  question: string,
  chatId: string,
  history: ChatbotMessage[],
  socketIOClientId?: string
};
export const useSendChatflowInternalMessagePrediction = () => {
  return useMutation({
    async mutationFn(params: SendChatflowInternalMessagePredictionParams) {
      const { chatflowId, ...rest } = params;
      const { data } = await client.post(`/internal-prediction/${chatflowId}`, rest);
      return ChatMessage.load(data)
    },
    onSuccess() {
      queryClient.invalidateQueries(ChatflowRequests.all)
      queryClient.invalidateQueries(ChatflowRequests.pagination)
    },
  });
}

export const useIncrementChatflowCopyCount = () => {
  return useMutation({
    async mutationFn(id: string) {
      client.patch(`/chatflows/${id}/increment-copy`);
    }
  });
}