import { ApiDataResult } from "../typings"

export class Variable {
  id: string
  name: string
  value: string
  type: string
  createdDate: Date
  updatedDate: Date

  static load(data: ApiDataResult) {
    const model = new Variable();
    model.id = data['id'];
    model.name = data['name'];
    model.value = data['value'];
    model.type = data['type'];
    model.createdDate = !!data['createdDate'] && new Date(data['createdDate']) || undefined;
    model.updatedDate = !!data['updatedDate'] && new Date(data['updatedDate']) || undefined;
    return model;
  }

  toJSON() {
    return {
      id: this.id,
      name: this.name,
      value: this.value,
      type: this.type,
      createdDate: this.createdDate,
      updatedDate: this.updatedDate,
    }
  }
}