import { PropsWithFormItem } from '@/ui-component/form/form-item.types'
import { TextField, MenuItem } from '@mui/material'
import React from 'react'

type SelectTextInputProps = {
  options: any[],
  valueKeyName?: string
  labelKeyName?: string
}
export default function SelectTextInput(props: PropsWithFormItem<SelectTextInputProps>) {
  const {
    name,
    label,
    options,
    labelKeyName = 'label',
    valueKeyName = 'value',
    onChange,
    defaultValue,
    helperText
  } = props
  return (
    <TextField
      select
      name={name}
      label={label}
      defaultValue={defaultValue}
      onChange={onChange}
      helperText={helperText}
    >
      {options.map((option) => (
        <MenuItem key={option[valueKeyName]} value={option[valueKeyName]}>
          {option[labelKeyName]}
        </MenuItem>
      ))}
    </TextField>
  )
}
