import { useAppContext } from "@/context/app.context";
import { Badge, Box } from '@mui/material';
import React, { PropsWithChildren, useMemo } from 'react';

const SubscriptionBadge = ({ children }: PropsWithChildren) => {
  const { userSubscription } = useAppContext();

  const subType = useMemo(() => {
    if (!!userSubscription) {
      const { product } = userSubscription;
      const { title, code } = product;
      return { title, code };
    }
  }, [userSubscription]);

  return <Box className='profile-subscription' component='div'>
    {!subType && (<>{children}</>)}
    {!!subType && (
      <Badge
        color={subType.code === 'gaia-free' ? "secondary" : "primary"}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        variant='dot'
        sx={{
          whiteSpace: 'nowrap',
        }}
      >
        {children}
      </Badge>
    )}
  </Box>
}

export default SubscriptionBadge