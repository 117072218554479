declare global {
  interface Window {
    env: any
  }
}

type EnvType = {
  REACT_APP_CLARK_PUB_KEY: string;
  REACT_APP_GAIA_ENV: string;
  REACT_APP_PORT: string;
  REACT_APP_STRIPE_PRICING_TABLE_ID: string;
  REACT_APP_STRIPE_PRICING_PUBLISHABLE_KEY: string;
  REACT_APP_SERVER_URL: string;
}

export const env: EnvType = { ...process.env, ...window.env }