import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getRemoteConfig } from 'firebase/remote-config';
import AppSettings from '@/config/main';

export const firebaseApp = initializeApp(AppSettings.firebaseConfig);

export const firebaseRemoteConfig = getRemoteConfig(firebaseApp);
firebaseRemoteConfig.settings.minimumFetchIntervalMillis = 3000;

export const firebaseAnalytics = getAnalytics(firebaseApp);