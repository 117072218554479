import { Box, Skeleton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React from 'react'

type DataTableSkeletonProps = {
  columnsCount?: number,
  rowsCount?: number
}

export default function DataTableSkeleton(props: DataTableSkeletonProps) {
  const { columnsCount = 6, rowsCount = 10 } = props;

  const columns = Array.from(Array(columnsCount).keys());
  const rows = Array.from(Array(rowsCount).keys());

  return (
    <Box
      component='div'
      sx={{
        minHeight: 400
      }}
    >
      <Skeleton variant='text' width='100%' height={30} />
      <Skeleton variant='text' width='80%' height={30} />
      <Skeleton variant='text' width='60%' height={30} />
      <Skeleton variant='text' width='40%' height={30} />
    </Box>
  )
}
