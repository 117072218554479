import { Pagination } from '@/components';
import { usePaginationProvider, usePaginationState } from '@/hooks/pagination.hooks';
import { IPagination, PaginationProviderParams } from '@/typings';
import ErrorContent from '@/ui-component/error-content/ErrorContent';
import { debounce } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import DataTable, { DataTableProps } from './DataTable';

type DataTablePaginationProps<T> = {
  /**
   * DataTable used to render DataTable component
   */
  DataTableProps: Omit<DataTableProps<T>, "dataSource">;

  /**
   * Pagination Provider Params
   * @type PaginationProviderParams
   */
  paginationProviderParams: PaginationProviderParams<T>;
}

export default function DataTablePaginated<T>(props: DataTablePaginationProps<T>) {
  const { DataTableProps, paginationProviderParams } = props;
  const { pageSize, currentPage, setCurrentPage, setPageSize } = usePaginationState()
  const { data: pagination, isLoading, refetch, isError, isPreviousData } = usePaginationProvider<T>({
    ...paginationProviderParams,
    paginationParams: {
      ...paginationProviderParams.paginationParams,
      currentPage,
      pageSize
    }
  });

  const runLoadData = debounce(() => {
    refetch();
  }, 200)

  useEffect(() => {
    runLoadData();
  }, [currentPage, pageSize])

  if (isError) {
    return <ErrorContent onRetry={runLoadData} />
  }

  const { data, ...rest } = useMemo(() => !!pagination ? pagination : { data: [], }, [pagination]);

  return (
    <>
      {
        !!rest && (
          <Pagination
            pagination={rest as IPagination}
            onPageSizeChange={!isPreviousData && setPageSize}
            onPageClick={!isPreviousData && setCurrentPage}
            sx={{my:2}}
          />
        )
      }
      <DataTable<T>
        {...DataTableProps}
        dataSource={data || []}
        isLoading={isLoading}
      />
    </>
  )
}
