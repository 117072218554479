import { Stack, SxProps, Typography } from '@mui/material';
import React from 'react';

type LabelValueProps = {
  label: string,
  value: string | React.ReactNode,
  separatorChar?: string,
  direction?: 'row' | 'column',
  sxContainer?: SxProps;
}

export default function LabelValue(props: LabelValueProps) {
  const { label, value, direction = 'row', sxContainer, separatorChar = '' } = props;
  return (
    <Stack
      direction={direction}
      sx={{
        overflow: 'hidden',
        ...sxContainer
      }}
      alignItems={direction === 'column' ? 'flex-start' : 'center'}
      justifyContent={direction === 'row' ? 'space-between' : 'center'}
    >
      <Typography variant='label'>{label}{separatorChar}</Typography>
      <Typography variant='labelValue'>{value}</Typography>
    </Stack>
  )
}
