type ConvertJSONKeyValueOptions = {
  keyProp?: string,
  valueProp?: string
}

export function convertJSONToKeyValue(json: Record<string, any>, options?: ConvertJSONKeyValueOptions): Record<string, any>[] {
  const values = Object.values(json);
  const { keyProp = 'keyItem', valueProp = 'valueItem' } = options || {};
  return Object.keys(json).map((key, i) => {
    return {
      [keyProp]: key,
      [valueProp]: values[i]
    }
  });
}

export function convertKeyValueToJSON(keyValuePairs: Record<string, any>[], options?: ConvertJSONKeyValueOptions): Record<string, any> {
  const { keyProp = 'keyItem', valueProp = 'valueItem' } = options || {};
  let result: any = {};
  keyValuePairs.forEach(item => {
    result[item[keyProp]] = item[valueProp]
  });
  return result;
}

export function getDeepValueFromPath(obj: Record<string, any>, path: string): any | null {
  try {
    let result: Record<string, any> | any = obj;
    const paths = path.split('.');
    while (paths.length > 0) {
      const current = paths.shift();
      result = result[current];
      if (paths.length === 0) {
        return result;
      }
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};