import { useLoadAllTools } from '@/hooks/tool.hooks';
import { Tool } from '@/models/Tool';
import ToolForm from '@/views/tools/ToolForm';
import { Add } from '@mui/icons-material';
import { Autocomplete, Avatar, Box, Button, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DrawerLayer from '../drawer-layer/DrawerLayer';

type ToolPickerProps = {
  value?: string;
  label?: string;
  disabled?: boolean;
  onChange?: (toolId: string, tool?: Tool) => void;
  formatValue?: (model: Tool) => any;
}
export default function ToolPicker(props: ToolPickerProps) {
  const { value, onChange, disabled, formatValue } = props;
  const { data: tools, isLoading, refetch, remove: removeFromCache } = useLoadAllTools();
  const [choosed, setChoosed] = useState<Tool | null>(null);

  const handleOnCreateNewTool = async (tool: Tool) => {
    removeFromCache();
    await refetch();
    setChoosed(tool);
  }

  useEffect(() => {
    if (!!choosed) {
      const returnValue = !!formatValue ? formatValue(choosed) : choosed.id;
      !!onChange && onChange(returnValue, choosed);
    }
  }, [choosed])

  useEffect(() => {
    console.log(value);
    
    if (!choosed && (tools && tools.length > 0) && value) {
      const c = tools.find(x => x.id === value);
      setChoosed(c);
    }
  }, [tools, value, choosed]);

  useEffect(() => {
    return () => {
      removeFromCache();//remove cache when component is unmounted
    }
  }, []);

  return (
    <Stack direction='column' gap={1}>
      <Stack direction='row' alignItems='center'>
        <Box flexGrow={1}>
          {!!choosed && (
            <Stack direction='row' alignItems='center' gap={2}>
              <Avatar src={choosed.iconSrc} sx={{ width: 40, height: 40 }} />
              <Typography variant='label'>{choosed.name}</Typography>
            </Stack>
          )}
        </Box>
        <DrawerLayer
          content={(openDrawer) => (
            <Button
              variant='outlined'
              size='small'
              color='secondary'
              property='action'
              onClick={openDrawer}
              startIcon={<Add />}
            >
              Create new Tool
            </Button>
          )}
          as={ToolForm}
          actionType='standart'
          onClose={handleOnCreateNewTool}
        />
      </Stack>
      <Box>
        <Autocomplete
          disableClearable={true}
          options={tools || []}
          loading={isLoading}
          value={choosed}
          disabled={disabled}
          onChange={(e, selection) => {
            setChoosed(selection)
          }}
          getOptionKey={option => option.id}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => <TextField {...params} placeholder='Search Tool by Name' />}
          renderOption={(props, option) => (
            <Box component='li' {...props}>
              <Avatar src={option.iconSrc} sx={{ width: 40, height: 40 }} />
              <Typography ml={1} variant='h5'>{option.name}</Typography>
            </Box>
          )}
        />
      </Box>
    </Stack>
  )
}
