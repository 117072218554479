import { useMutation, useQuery } from "react-query"
import client from "@/api/client";
import { Tool } from '@/models/Tool';
import queryClient from "@/utils/query-client";
import { delayMs } from "@/utils/timer";
import AppSettings from "@/config/main";

export enum ToolRequests {
  all = 'tool.all',
  pagination = 'tool.pagination',
  detail = 'tool.detail'
}

export const useLoadAllTools = () => {
  return useQuery<Tool[], Error>(ToolRequests.all, async () => {
    const { data } = await client.get("/tools");
    await delayMs(AppSettings.defaultRequestDelay);
    return data.map(Tool.load)
  });
}

export const useLoadToolDetail = (id: string) => {
  return useQuery<Tool, Error>(ToolRequests.detail, async () => {
    const { data } = await client.get(`/tools/${id}`);
    await delayMs(AppSettings.defaultRequestDelay);
    return Tool.load(data)
  });
}

export const useCreateToolMutation = () => {
  return useMutation({
    async mutationFn(tool: Tool) {
      const data = tool.toFormData();
      const result = await client.post('/tools', data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return result.data
    },
    onSuccess() {
      queryClient.invalidateQueries(ToolRequests.all)
      queryClient.invalidateQueries(ToolRequests.pagination)
    },
  });
}

export const useUpdateToolMutation = () => {
  return useMutation({
    async mutationFn(tool: Tool) {
      const formData = tool.toFormData();
      const result = await client.put(`/tools/${tool.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return result.data
    },
    onSuccess() {
      queryClient.invalidateQueries(ToolRequests.all)
      queryClient.invalidateQueries(ToolRequests.pagination)
    }
  });
}

export const useToolIncrementCopyCountMutation = () => {
  return useMutation({
    async mutationFn(toolId: string) {
      client.patch(`/tools-increment-copy/${toolId}`);
    }
  });
}

export const useDeleteToolMutation = () => {
  return useMutation({
    async mutationFn(id: string) {
      const result = await client.delete(`/tools/${id}`);
      return result
    },
    onSuccess() {
      queryClient.invalidateQueries(ToolRequests.all)
      queryClient.invalidateQueries(ToolRequests.pagination)
    }
  });
}