
import { ApiDataResult } from "@/typings"

export class ChatFlow {
  id: string
  userId?: string
  user?: any;
  name: string;
  icon?: string;
  iconFile?: File;
  description?: string;
  flowData: string
  flowConfig: Record<string, any>;
  deployed: boolean
  public: boolean
  shareable: boolean
  apiKeyId?: string;
  apiKey?: any;
  chatbotConfig?: Record<string, any>;
  apiConfig?: any
  analytic?: any
  category?: string
  createdDate: Date
  updatedDate: Date
  copyCount: number

  static load(data: ApiDataResult) {
    const model = new ChatFlow();
    model.id = data['id'];
    model.userId = data['userId'];
    model.name = data['name'];
    model.description = data['description'];
    model.icon = data['icon'];
    model.iconFile = data['iconFile'];
    model.flowData = data['flowData'] || '{}';
    model.flowConfig = data['flowConfig'] || { nodes: [], edges: [], viewport: {} };
    model.deployed = data['deployed'] || false;
    model.public = data['public'];
    model.apiKeyId = data['apiKeyId'];
    model.chatbotConfig = data['chatbotConfig'] || {};
    model.apiConfig = data['apiConfig'];
    model.analytic = data['analytic'];
    model.category = data['category'];
    model.shareable = data['shareable'];
    model.createdDate = !!data['createdDate'] && new Date(data['createdDate']) || undefined;
    model.updatedDate = !!data['updatedDate'] && new Date(data['updatedDate']) || undefined;
    return model;
  }

  getNodesNameIcon(): { icon: string, name: string }[] {
    let listNodes = (this.flowConfig as { nodes: any }).nodes || [];
    return listNodes.map(node => ({ icon: node.data.icon, name: node.data.name }))
  }

  isNew() {
    return !this.id;
  }

  toFormData(): FormData {
    const formData = new FormData();
    !!this.id && formData.append('id', this.id);
    !!this.name && formData.append('name', this.name);
    !!this.description && formData.append('description', this.description);
    !!this.icon && formData.append('icon', this.icon);
    !!this.iconFile && formData.append('iconFile', this.iconFile);
    !!this.flowConfig && formData.append('flowConfig', JSON.stringify(this.flowConfig));
    !!this.flowData && formData.append('flowData', this.flowData);
    formData.append('deployed', JSON.stringify(this.deployed));
    formData.append('public', JSON.stringify(this.public));
    formData.append('shareable', JSON.stringify(this.shareable));
    !!this.apiKeyId && formData.append('apiKeyId', this.apiKeyId);
    !!this.chatbotConfig && formData.append('chatbotConfig', JSON.stringify(this.chatbotConfig));
    !!this.apiConfig && formData.append('apiConfig', this.apiConfig);
    !!this.analytic && formData.append('analytic', this.analytic);
    !!this.category && formData.append('category', this.category);
    return formData;
  }

  toJSON() {
    return {
      id: this.id,
      userId: this.userId,
      name: this.name,
      description: this.description,
      icon: this.icon,
      iconFile: this.iconFile,
      flowConfig: this.flowConfig,
      flowData: this.flowData,
      deployed: this.deployed,
      public: this.public,
      shareable: this.shareable,
      apiKeyId: this.apiKeyId,
      chatbotConfig: this.chatbotConfig,
      apiConfig: this.apiConfig,
      analytic: this.analytic,
      createdDate: this.createdDate,
      updatedDate: this.updatedDate,
      category: this.category,
    }
  }
}