import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import enTranslation from '@/locales/en/translation.json'
import enCommon from '@/locales/en/common.json'
import ptTranslation from '@/locales/pt/translation.json'
import ptCommon from '@/locales/pt/common.json' 
import esTranslation from '@/locales/es/translation.json'
import esCommon from '@/locales/es/common.json'
import frTranslation from '@/locales/fr/translation.json'
import frCommon from '@/locales/fr/common.json'

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    supportedLngs: ['pt', 'en', 'es', 'fr'],
    fallbackLng: 'en',
    fallbackNS: 'common',
    contextSeparator:'@',
    ns:['translation', 'common'],
    resources: {
      en: {
        translation: enTranslation,
        common: enCommon
      },
      pt: {
        translation: ptTranslation,
        common: ptCommon
      },
      es: {
        translation: esTranslation,
        common: esCommon
      },
      fr: {
        translation: frTranslation,
        common: frCommon
      },
    },
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;