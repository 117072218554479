import { UseQueryResult } from "react-query";
import { Subscription } from "./models/Subscription";
import { TypographyOptions } from "@mui/material/styles/createTypography";
import { ComponentPropsWithoutRef, ElementType, ReactNode } from "react";
import "@mui/material/styles/createPalette";

//GLOBAL
interface GaiachatbotHTMLAttributes extends React.HTMLAttributes<HTMLElement> {
  chatflowid?: string;
}
declare global {
  namespace JSX {
    interface IntrinsicElements {
      'stripe-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
      'gaiahub-chatbot': {
        chatflowid?: string;
      };
      'gaiahub-fullchatbot': {
        chatflowid?: string;
      };
    }
  }
  interface Window {
    newrelic: any
  }
}

//TPOGRAPHY
declare module '@mui/material/styles' {
  interface TypographyVariants {
    title: React.CSSProperties;
    label: React.CSSProperties;
    labelValue: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    title?: React.CSSProperties;
    label?: React.CSSProperties;
    labelValue?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    title: true;
    label: true;
    labelValue: true
  }
}

export interface ExtendedTypographyOptions extends TypographyOptions {
  title: React.CSSProperties;
}

import "@mui/material/styles/createPalette";
declare module "@mui/material/styles/createPalette" {
  interface CommonColors {
    purple: string;
    blue: string;
    orange: string;
  }
}

export interface ExtendedTypographyOptions extends TypographyOptions {
  title: React.CSSProperties;
}

export interface ApiDataResult {
  [key: string]: any
}

export enum ComponentType {
  APP = 'app',
  TOOL = 'tool'
};

export type ComponentTypes = `${ComponentType}`;

export interface SearchResultData {
  title: string,
  firstLetter: string,
  image?: string;
  type: ComponentTypes,
  description: string,
  model: any
}
//Chat Message
export enum MessageTypes {
  apiMessage = 'apiMessage',
  userMessage = 'userMessage',
  infoMessage = 'infoMessage',
  userMessageWaiting = 'usermessagewaiting',
}
export type MessageType = `${MessageTypes}`
export type ChatbotMessage = {
  type: MessageType;
  message: string;
  sourceDocuments?: any;
  usedTools?: any;
  fileAnnotations?: any;
}

//contexts
export interface IAppContext {
  userSubscription?: Subscription;
  isLoadingSubscription: boolean;
  isSubscriptionLoaded: boolean;
  isSubscriptionLoadingError: boolean;
  isSidebarOpen: boolean;
  smallScreen: boolean;
  setSidebarOpen: (isOpen: boolean) => void;
  toggleSidebar: () => void;
  selectedPage: any,
  setSelectedPage: (selectedMenu: any) => void;
}

export type PalleteColors = "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning";
export type DefaultSizes = "small" | "medium" | "large";

export enum SubscriptionStatusEnum {
  created = 'created',
  succeeded = 'succeeded',
  cancelled = 'cancelled',
  failed = 'failed',
  processing = 'processing',
  requires_action = 'requires_action',
}

export type SubscriptionStatuses = `${SubscriptionStatusEnum}`;
export enum NodeParamTypes {
  credential = 'credential',
  asyncOptions = 'asyncOptions',
  options = 'options',
  multiOptions = 'multiOptions',
  datagrid = 'datagrid',
  string = 'string',
  number = 'number',
  boolean = 'boolean',
  password = 'password',
  json = 'json',
  code = 'code',
  date = 'date',
  file = 'file',
  folder = 'folder'
}
export type NodeParamTypesString = `${NodeParamTypes}`;
export interface NodeParam {
  id: string,
  name: string,
  label: string,
  type: NodeParamTypesString,
  hidden?: boolean,
  additionalParams?: boolean,
  optional?: boolean,
  description?: string,
  placeholder?: string,
  rows?: number,
  default?: any,
  fileType?: string,
  step?: number;
  loadMethod?: "listTools" | "listAssistants";
  credentialNames?: string[];
  options?: { name: string, label: string }[]
};

export type PaginationProvider<T> = UseQueryResult<PaginationResult<T>, Error>;
export interface IPagination {
  pageSize: number;
  currentPage: number;
  totalItems: number;
  totalPages: number;
}

export interface PaginationResult<T> extends IPagination {
  data: T[]
}

export interface PaginationProviderParams<T> {
  queryKey: string;
  endpoint: string;
  parser: (data: Record<string, any>) => T,
  paginationParams: PaginationRequestParams<T>
}
export interface PaginationRequestParams<T> {
  currentPage?: number,
  pageSize?: number;
  relations?: RelationProvider,
  filters?: FilterProvider<T>
}
export type RelationProvider = string[]
export type FilterProvider<T> = {
  [P in keyof T]?: any;
};

export type RequestCriteria = {
  filters?: string,
  relations?: string[]
}

export type RequiredPolymorphicProps<T extends ElementType> = {
  as: T;
}

export type PolymorphicProps<T extends ElementType> = {
  as?: T;
} & ComponentPropsWithoutRef<T>

export type PolymorphicPropsWithChildren<T extends ElementType> = {
  as?: T;
  children: ReactNode
} & ComponentPropsWithoutRef<T>