

import { CredentialLibrary } from '@/models/CredentialComponent';
import UnsafeHtml from '@/ui-component/unsafe-html';
import { Refresh } from '@mui/icons-material';
import {
  Alert,
  Avatar, Box,
  IconButton,
  Stack, Typography
} from '@mui/material';
import '@mui/material/styles/';
import React from 'react';
import { TextFieldElement } from 'react-hook-form-mui';
import { useTranslation } from 'react-i18next';

type CredentialComponentDynamicFieldsProps = {
  component: CredentialLibrary,
  onChangeClick: () => void,
}

export default function CredentialComponentDynamicFields(props: CredentialComponentDynamicFieldsProps) {
  const { t } = useTranslation()
  const { component, onChangeClick } = props;

  return (
    <div>
      <Stack direction='row' gap={2} alignItems={'center'}>
        <Avatar alt="complex" src={component.icon} sx={{ width: 64, height: 64 }} />
        <Box flexGrow={1}>
          <Typography gutterBottom variant="h5" component="div">
            {component.library_name}
          </Typography>
          <Typography variant="body1" gutterBottom>
            <UnsafeHtml as='span'>
              {component.description}
            </UnsafeHtml>
          </Typography>
        </Box>
        <IconButton onClick={onChangeClick} size='large' color='success'>
          <Refresh />
        </IconButton>
      </Stack>

      <Alert severity="warning" variant='filled' sx={{ my: 2 }}>{t('credentials.form.dynamic_fields_warning')}</Alert>
      <Stack direction='column' gap={2}>
        <TextFieldElement name='name' label={t('credentials.form.field_name')} required />
        {
          (component.inputs as any[]).map(({ name, type, label }, index) => (
            <TextFieldElement
              key={`dynamic-field-${index}-${name}`}
              name={`connectionValues.${name}`}
              type={type}
              label={label}
              fullWidth
            />
          ))
        }
      </Stack>
    </div>
  )
}
