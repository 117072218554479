import imagePlaceholder from '@/assets/images/default-image.svg';
import { CardSkeleton, DrawerLayer, NodeAvatar, ReadMore } from '@/components';
import { ChatFlow } from '@/models/ChatFlow';
import AvatarComponent from '@/ui-component/avatar-component/AvatarComponent';
import ChatflowForm from '@/views/chatflows/ChatflowForm';
import { CopyAll, Delete, Edit, Schema } from '@mui/icons-material';
import { Avatar, AvatarGroup, Box, Card, CardActions, CardContent, CardHeader, Chip, IconButton, Skeleton, Stack, Tooltip } from '@mui/material';
import React, { useCallback, useMemo } from 'react';

type ChatflowCardProps = {
  chatflow: ChatFlow;
  loading?: boolean;
  onClickViewMore?: (chatflow: ChatFlow) => void;
  onClickDelete?: (chatflow: ChatFlow) => void;
}

export default function ChatfowCard(props: ChatflowCardProps) {
  const { chatflow, loading, onClickViewMore, onClickDelete } = props;

  const nodesImages = useMemo(() => {
    if (loading) {
      return [{}, {}, {}];
    }
    return (chatflow.flowConfig?.nodes || []).map(node => node.data);
  }, [chatflow, loading])

  const handleClickViewMore = useCallback(() => {
    !!onClickViewMore && onClickViewMore(chatflow);
  }, [chatflow])

  const handleClickDelete = useCallback(() => {
    !!onClickDelete && onClickDelete(chatflow)
  }, [chatflow])

  if (loading) {
    return <CardSkeleton />
  }

  const cardHeader = (
    <CardHeader
      avatar={<Avatar
        src={chatflow.icon || imagePlaceholder}
        sx={{
          boxShadow: '0px 0px 0px 2px var(--grey100)'
        }}
      />}
      title={chatflow.name}
      action={<AvatarComponent
        type='app'
      />}
      onClick={handleClickViewMore}
      sx={{
        '&:hover': {
          cursor: 'pointer'
        }
      }}
    />
  )

  const cardContent = (
    <CardContent sx={{ minHeight: 120 }}>
      <ReadMore
        limit={100}
        content={chatflow.description || 'No description'}
      />
    </CardContent>
  )

  const cardSubContent = (
    <>
      <Stack direction='row' gap={1} sx={{p:1}}>
        <Chip
          size='small'
          label={chatflow.public ? 'Public' : 'Private'}
          color={chatflow.public ? 'primary' : 'secondary'}
        />
        <Chip
          size='small'
          label={chatflow.shareable ? 'Shareable' : 'Not Shareable'}
          color={chatflow.shareable ? 'primary' : 'default'}
        />
      </Stack>
      <Box component='div' sx={{ height: 50, width: '100%' }} bgcolor={'background.paper'} p={1}>
        <AvatarGroup max={4} sx={{
          '&.MuiAvatarGroup-root': {
            bgcolor: 'background.paper',
          },
          '&.MuiAvatarGroup-avatar': {
            width: 30,
            height: 30,
          }
        }}
        >
          {nodesImages.map(({ icon, label }, index) => (
            <Tooltip key={`avatar-node-${index}`} title={label} placement='top'>
              <Box>
                <NodeAvatar
                  iconUrl={icon}
                  size={18}
                  title={label}
                  iconColor='primary.dark'
                  bgcolor='background.paper'
                />
              </Box>
            </Tooltip>
          ))}
        </AvatarGroup>
      </Box>
    </>
  )

  const cardActions = (
    <CardActions>
      <>
        <IconButton color='secondary' property='action' onClick={handleClickViewMore}>
          <Schema fontSize='small' />
        </IconButton>
        <DrawerLayer
          content={(openDrawer) => (
            <IconButton color='secondary' property='action' onClick={openDrawer}>
              <CopyAll fontSize='small' />
            </IconButton>
          )}
          as={ChatflowForm}
          chatflow={chatflow}
          actionType='copy'
        />

        <Stack direction='row' flexGrow={1} justifyContent={'flex-end'}>
          <DrawerLayer
            content={(openDrawer) => (
              <IconButton color='secondary' property='action' onClick={openDrawer}>
                <Edit fontSize='small' />
              </IconButton>
            )}
            as={ChatflowForm}
            chatflow={chatflow}
            actionType='standart'
          />
          <IconButton color='secondary' property='delete' onClick={handleClickDelete}>
            <Delete fontSize='small' />
          </IconButton>
        </Stack>
      </>
    </CardActions>
  )

  const card = (
    <Card variant='outlined'>
      {cardHeader}
      {cardContent}
      {cardSubContent}
      {cardActions}
    </Card >
  )

  return card;
}
