import { ApiDataResult } from "@/typings"

type AssistantDetail = {
    id: string;
    name: string;
    description: string;
    model: string;
    instructions: string;
    tools: string[],
    files: any[],
    uploadFiles: string | string[]
};

export class Assistant {
    id: string
    details: AssistantDetail | string;
    detailsData: AssistantDetail | null;
    credential: string;
    iconSrc?: string;
    iconFile?: File;
    createdDate: Date;
    updatedDate: Date;

    static load(data: ApiDataResult) {
        const model = new Assistant();
        model.id = data['id'];
        model.details = data['details'];
        model.detailsData = data['detailsData'] || (!!data['details'] && typeof data['details'] === 'string' && JSON.parse(data['details']));
        model.credential = data['credential'];
        model.iconSrc = data['iconSrc'];
        model.iconFile = data['iconFile'];
        model.createdDate = !!data['createdDate'] && new Date(data['createdDate']) || undefined;
        model.updatedDate = !!data['updatedDate'] && new Date(data['updatedDate']) || undefined;
        return model;
    }

    toJSON() {
        return {
            id: this.id,
            details: this.details,
            credential: this.credential,
            iconSrc: this.iconSrc,
            createdDate: this.createdDate,
            updatedDate: this.updatedDate,
        }
    }

    toFormData() {
        const formData = new FormData();
        !!this.id && formData.append('id', this.id);
        !!this.details && formData.append('details', JSON.stringify(this.details));
        !!this.credential && formData.append('credential', this.credential);
        !!this.iconFile && formData.append('iconFile', this.iconFile);
        !!this.iconSrc && formData.append('iconSrc', this.iconSrc);
        return formData;
    }
}
