import { Close } from '@mui/icons-material'
import { IconButton, Stack, Typography } from '@mui/material'
import React, { ReactNode } from 'react'

type DrawerLayerHeaderProps = {
  title: string;
  onClose: () => void,
  actions?: ReactNode | ReactNode[]
}
export default function DrawerLayerHeader(props: DrawerLayerHeaderProps) {
  const { title, onClose, actions } = props;
  return (
    <Stack direction='row' gap={2} p={2} alignItems='center'>
      <IconButton
        onClick={() => {
          onClose();
        }}
        color="secondary"
        property="action"
      >
        <Close />
      </IconButton>
      <Typography flexGrow={1} variant='h3'>
        {title}
      </Typography>
      {actions}
    </Stack>
  )
}
