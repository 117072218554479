
import { Paper, SxProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { useMemo } from 'react';
import { DataTableColumnConfig } from './data-table-typings';
import DataTableSkeleton from './DataTableSkeleton';
import { filterOnlyVisibleColumns, getCellPropsFromConfig, renderRowCell } from './utils';

import { useVisibility } from '@/hooks/visibility.hooks';
import './data-table.scss';

export type DataTableProps<T> = {
  dataSource: T[],
  config: DataTableColumnConfig<T>[],
  pageSize?: number,
  page?: number,
  isLoading?: boolean,
  emptyComponent?: React.ReactNode;
  sx?: SxProps,
}
export default function DataTable<T>(props: DataTableProps<T>) {
  const {
    dataSource, config, sx, isLoading, emptyComponent,
  } = props;
  const { isUpXs, isUpSm, isUpMd, isUpLg, isUpXl } = useVisibility();

  const visibilityMap = useMemo(() => ({
    xs: isUpXs,
    sm: isUpSm,
    md: isUpMd,
    lg: isUpLg,
    xl: isUpXl,
  }), [isUpXs, isUpSm, isUpMd, isUpLg, isUpXl]);
  const columns = useMemo(() => config.filter((c) => filterOnlyVisibleColumns(c, visibilityMap)), [config, visibilityMap]);
  const rows = useMemo(() => dataSource, [dataSource]);

  if (isLoading) {
    return <DataTableSkeleton />
  }

  if (!isLoading && dataSource && dataSource.length === 0 && !!emptyComponent) {
    return emptyComponent;
  }

  return (
    <TableContainer variant='outlined' component={Paper}>
      <Table size='small' sx={sx} aria-label="simple table">
        <TableHead>
          <TableRow>
            {columns.map((col, index) => {
              const sx = getCellPropsFromConfig(col);
              return (
                <TableCell key={`table-head-cell-${index}`} sx={sx}>
                  {col.headerName}
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((rowData, index) => (
            <TableRow
              key={`table-body-row-${index}`}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
              }}
            >
              {columns.map((col, colIndex) => {
                const { props, value } = renderRowCell({ data: rowData, rowIndex: index }, col, colIndex);
                return <TableCell
                  {...props}
                >
                  {value}
                </TableCell>
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
