import { ApiDataResult } from "@/typings"
import { CredentialLibrary } from "./CredentialComponent";

export class Credential {
  id: string
  name: string
  credentialLibraryId: string
  credentialLibrary: CredentialLibrary;
  connectionValues: {}
  createdDate: Date
  updatedDate: Date

  static load(data: ApiDataResult) {
    const model = new Credential();
    model.id = data['id'];
    model.name = data['name'];
    model.credentialLibraryId = data['credentialLibraryId'];
    model.connectionValues = data["connectionValues"] || {};
    if (!!data['credentialLibrary']) {
      model.credentialLibrary = CredentialLibrary.load(data['credentialLibrary'])
      model.credentialLibraryId = model.credentialLibrary.id;
    }
    model.createdDate = !!data['createdDate'] && new Date(data['createdDate']) || undefined;
    model.updatedDate = !!data['updatedDate'] && new Date(data['updatedDate']) || undefined;
    return model;
  }

  get icon() {
    if (this.credentialLibrary) {
      return this.credentialLibrary.iconUrl;
    }
    return '';
  }

  toJSON() {
    return {
      id: this.id,
      name: this.name,
      credentialLibraryId: this.credentialLibraryId,
      connectionValues: this.connectionValues,
      createdDate: this.createdDate,
      updatedDate: this.updatedDate,
    }
  }

  isNew() {
    return !!this.id
  }
}