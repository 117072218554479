import { ApiDataResult } from "@/typings"

export class CredentialLibrary {
  id: string
  library_name: string
  description: string
  type: string
  icon?: string
  inputs: {}
  version: number
  createdDate: Date
  updatedDate: Date

  get iconUrl() {
    let iconFileName = 'default.svg';
    if (!!this.icon) {
      iconFileName = this.icon as string;
    }

    return `${iconFileName}`
  }

  static load(data: ApiDataResult) {
    const model = new CredentialLibrary();
    model.id = data['id'];
    model.library_name = data['library_name'];
    model.description = data['description'];
    model.type = data['type'];
    model.icon = data['icon'];
    model.inputs = data['inputs'];
    model.version = data['version'];
    model.createdDate = !!data['createdDate'] && new Date(data['createdDate']) || undefined;
    model.updatedDate = !!data['updatedDate'] && new Date(data['updatedDate']) || undefined;
    return model;
  }

  toJSON() {
    return {
      id: this.id,
      library_name: this.library_name,
      description: this.description,
      type: this.type,
      icon: this.icon,
      inputs: this.description,
      version: this.version,
      createdDate: this.createdDate,
      updatedDate: this.updatedDate,
    }
  }
}