import { FormControl, FormControlLabel, Stack, Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import { PropsWithFormItem } from './form-item.types';

type FormItemCustomProps = {
  required?: boolean;
  multiline?: boolean;
  rows?: number;
  component: ReactNode;
  helperText?: string;
  action?: ReactNode;
}
export default function FormItemCustom(props: PropsWithFormItem<FormItemCustomProps>) {
  const { name, label, required = false, component, helperText, action } = props
  return (
    <Stack direction={'column'} gap={2}>
      <Typography variant='h4'>
        {label}
      </Typography>
      {(!!helperText || !!action) && (
        <Stack direction='row' gap={2} alignItems='center'>
          {!!helperText && (
            <Typography variant='caption' flexGrow={1}>
              {helperText}
            </Typography>
          )}
          {!!action && (
            <>{action}</>
          )}
        </Stack>
      )}
      {component}
    </Stack>
  )
}

