import { Components } from "@mui/material/styles";
import { Colors } from "./theme.typings";

export default function componentStyleOverrides(colors: Colors): Components {
  return {
    MuiCard: {
      defaultProps: {
        variant: 'outlined',
      }
    },
    MuiIcon: {
      defaultProps: {
        fontSize: 'small'
      },
    },
    MuiSkeleton: {
      defaultProps: {
        variant: 'text',
        animation: 'wave',
        width: '100%',
        height: 30
      }
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'outlined', color: 'secondary', property: 'delete' },
          style: {
            '&:hover': {
              borderColor: colors.errorDark,
              color: colors.errorDark
            }
          }
        },
        {
          props: { variant: 'outlined', color: 'secondary' },
          style: {
            '&:hover': {
              borderColor: colors.primaryMain,
              color: colors.primaryMain
            }
          }
        }
      ],
      styleOverrides: {
        root: {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        },
      },
    },
    MuiFab: {
      variants: [
        {
          props: { color: 'primary', property: 'action', variant: 'extended' },
          style: {
            backgroundColor: colors.blueMain,
            '&:hover': {
              backgroundColor: colors.blueDark
            }
          }
        }
      ]
    },
    MuiIconButton: {
      variants: [
        {
          props: { color: 'secondary', property: 'delete' },
          style: {
            '&:hover': {
              borderColor: colors.errorDark,
              color: colors.errorDark
            }
          }
        },
        {
          props: { color: 'secondary', property: 'edit' },
          style: {
            '&:hover': {
              borderColor: colors.primaryMain,
              color: colors.primaryMain
            }
          }
        },
        {
          props: { color: 'secondary', property: 'action' },
          style: {
            '&:hover': {
              borderColor: colors.primaryMain,
              color: colors.primaryMain
            }
          }
        }
      ]
    },
    MuiCardHeader: {
      styleOverrides: {
        content: {
          overflow: 'hidden',
        },
        title: {
          fontSize: 18,
          fontWeight: 400,
          color: colors.textPrimary,
          width: '100%',
          display: 'block',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis'
        },
        subheader: {
          color: colors.textSecondary,
          width: '100%',
          display: 'block',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis'
        }
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: '16px',
          textAlign: 'center'
        },
      },
    },
    MuiTableHead: {
      defaultProps: {
        className: 'gaiahub-table-head'
      },
    },
    MuiTableBody: {
      defaultProps: {
        className: 'gaiahub-table-body'
      },
    },
    MuiTableFooter: {
      defaultProps: {
        className: 'gaiahub-table-footer'
      },
    },
    MuiTableContainer: {
      defaultProps: {
        className: 'gaiahub-table'
      }
    },
    MuiTableCell: {
      defaultProps: {
        className: 'gaiahub-table-cell'
      }
    },
    MuiTableRow: {
      defaultProps: {
        className: 'gaiahub-table-row'
      }
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h1',
          h2: 'h2',
          h3: 'h3',
          h4: 'h4',
          h5: 'h5',
          h6: 'h6',
          subtitle1: 'h6',
          subtitle2: 'h6',
          body1: 'span',
          body2: 'span',
          title: 'h3',
          label: 'span',
          labelValue: 'span'
        },
      },
    }
  };
}
