import { gridSpacing } from '@/store/constant';
import { Grid } from '@mui/material';
import React, { ReactNode, useMemo } from 'react'
import GridViewSkeleton from './GridViewSkeleton';

export type GridViewProps<T> = {
  data: T[],
  emptyComponent?: ReactNode,
  isLoading?: boolean;
  skeletonTotalCount?: number;
  maxColumns?: number;
  renderItem: (item: T, loading?: boolean) => ReactNode
}
export default function GridView<T>(props: GridViewProps<T>) {
  const { data, renderItem, emptyComponent, isLoading, skeletonTotalCount = 5, maxColumns = 4 } = props;

  if (!isLoading && data.length === 0 && !!emptyComponent) {
    return <>{emptyComponent}</>
  }

  if (isLoading) {
    return <GridViewSkeleton
      count={skeletonTotalCount}
      gridSpacing={gridSpacing}
      renderItem={renderItem}
    />
  }

  const { spacing, gridConfig } = useMemo(() => {
    let spacing = gridSpacing;
    let gridConfig = {};

    if (maxColumns === 4) {
      gridConfig = {
        lg: 3,
        md: 4,
        sm: 6,
        xs: 12,
      }
    } else if (maxColumns === 3) {
      gridConfig = {
        lg: 4,
        md: 6,
        sm: 6,
        xs: 12
      }
    } else if (maxColumns <= 2) {
      gridConfig = {
        lg: 6,
        md: 6,
        sm: 12,
      }
    }

    return { spacing, gridConfig }
  }, [maxColumns])

  return (
    <Grid container spacing={spacing}>
      {data.map((item, index) => (
        <Grid key={`grid-view-item-${index}}`} item
          {...gridConfig}
        >
          {renderItem(item)}
        </Grid>
      ))}
    </Grid>
  )
}
