import React from 'react'

interface DangerouslyRenderHtmlProps {
  as: keyof JSX.IntrinsicElements;
  children: string;
  [key: string]: unknown;
}

export default function UnsafeHtml(props: DangerouslyRenderHtmlProps) {
  const { children, as: Element, ...rest } = props;

  return (
    <Element
      dangerouslySetInnerHTML={{ __html: children }}
      {...rest}
    />
  )
}
