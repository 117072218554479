
import { DrawerContainer, DrawerLayerContent, DrawerLayerFooter, DrawerLayerHeader, IconPicker } from '@/components'
import { PropsWithCloseDrawer } from '@/components/drawer-layer/drawer-layer.types'
import FormCustomElement from '@/components/form/FormCustomElement'
import { useSnackbarNotification } from '@/hooks/snackbar.hooks'
import { useCreateToolMutation, useToolIncrementCopyCountMutation, useUpdateToolMutation } from '@/hooks/tool.hooks'
import { Tool } from '@/models/Tool'
import { FormItemCustom } from '@/ui-component/form/Form'
import useNotifier from '@/utils/useNotifier'
import { Box, Button, Stack } from '@mui/material'
import React, { useMemo } from 'react'
import { FormContainer, SwitchElement, TextFieldElement } from 'react-hook-form-mui'
import ToolCodeEditor from './ToolCodeEditor'
import ToolPropertiesManager from './ToolPropertiesManager'
import { useTranslation } from 'react-i18next'

type ToolFormProps = {
  toolModel?: Partial<Tool>
  actionType: 'standart' | 'copy'
}
function ToolForm(props: PropsWithCloseDrawer<ToolFormProps>) {
  useNotifier();
  const { t } = useTranslation();
  const { sucessSnackbar, errorSnackbar } = useSnackbarNotification();
  const { closeDrawer, toolModel, actionType = 'standart' } = props
  const tool = useMemo(() => toolModel || {}, [toolModel])

  const createToolMutation = useCreateToolMutation();
  const updateToolMutation = useUpdateToolMutation();
  const incrementToolCopyCountMutation = useToolIncrementCopyCountMutation();

  const handleSaveToolClick = async (data) => {
    const instance = Tool.load(data);
    try {
      if (!!tool.id && actionType === 'standart') {
        const result = await updateToolMutation.mutateAsync(instance);
        sucessSnackbar(t('tools.form.updated_success'));
        !!closeDrawer && closeDrawer(result)
      } else {

        if (actionType === 'copy') {
          incrementToolCopyCountMutation.mutateAsync(tool.id);
          instance.id = undefined;
        }

        const result = await createToolMutation.mutateAsync(instance);
        sucessSnackbar(t('tools.form.created_success'));
        !!closeDrawer && closeDrawer(result)
      }
    } catch (error) {
      const { response } = error
      errorSnackbar(response?.data || error.message);
    }
  }

  const drawerTitle = useMemo(() => {
    if (actionType === 'standart') {
      return !!tool.id ? t('tools.form.title_update') : t('tools.form.title_create');
    } else {
      return t('tools.form.title_copy', 'Copy Tool');
    }
  }, [tool, actionType]);

  return (
    <DrawerContainer
      as={FormContainer}
      defaultValues={tool}
      onSuccess={handleSaveToolClick}
      width={700}
    >
      <DrawerLayerHeader
        title={drawerTitle}
        onClose={closeDrawer}
      />
      <DrawerLayerContent>
        <Stack direction='row' gap={2} alignItems={'center'}>
          <FormCustomElement
            name='iconFile'
            as={IconPicker}
            resultType='file'
            defaultImage={tool.iconSrc}
          />
          <TextFieldElement name='name' label={t('tools.form.field_name')} fullWidth required />
        </Stack>
        <SwitchElement name='shareable' label={t('tools.form.field_shareable', 'Is this tool shareable?')} />
        <TextFieldElement name='description' multiline rows={5} label={t('tools.form.field_description')} required fullWidth />
        <ToolPropertiesManager name='schema' />
        <FormItemCustom
          label={t('tools.form.field_func')}
          name='func'
          helperText='You can use here any variable create by you, just typing $vars.[VARIABLE NAME]'
          component={(
            <Box component='div' sx={{ minHeight: 200 }}>
              <ToolCodeEditor name='func' />
            </Box>
          )}
        />
      </DrawerLayerContent>
      <DrawerLayerFooter center>
        <Button
          color='primary'
          variant='contained'
          sx={{ width: 300 }}
          type='submit'
        >
          {actionType === 'copy' && t('tools.form.submit_copy')}
          {actionType === 'standart' && !!tool.id && t('tools.form.submit_update')}
          {actionType === 'standart' && !tool.id && t('tools.form.submit_create')}
        </Button>
      </DrawerLayerFooter>
    </DrawerContainer>
  )
}

export default ToolForm
