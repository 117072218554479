import { Box } from '@mui/material'
import React from 'react'
import logoImgShort from '@/assets/images/gaia_logo.png'
import logoImgLong from '@/assets/images/gaia_logo_long.png'

export default function LogoSection({showBigLogo}) {
  return (
    <Box component='div' className='main-layout-header-logo'>
      {!showBigLogo && (
        <Box
        component='img'
        src={logoImgShort}
        sx={{
          maxWidth: 35
        }}
      />) }
      {showBigLogo && (
        <Box
          component='img'
          src={logoImgLong}
          sx={{
            maxWidth: 160
          }}
        />) }
    </Box>

  )
}
