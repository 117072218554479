import { ApiDataResult } from "@/typings";
import { dataURLtoFile } from "@/utils/file";
import { slugify } from "@/utils/string";

export class Tool {
  id: string;
  name: string;
  code: string;
  color: string;
  description: string;
  func: string;
  iconSrc: string;
  shareable: boolean;
  iconFile?: File;
  schema: {};
  createdDate: Date;
  updatedDate: Date;
  user?:any;
  copyCount: number
  
  static load(data: ApiDataResult): Tool {
    debugger;
    const model = new Tool();
    model.id = data['id'];
    model.name = data['name'];
    model.code = data['code'];
    model.color = data['color'];
    model.description = data['description'];
    model.func = data['func'];
    model.iconSrc = data['iconSrc'];
    model.iconFile = data['iconFile'];
    model.schema = data['schema'];
    model.shareable = data['shareable'];
    model.createdDate = !!data['createdDate'] && new Date(data['createdDate']) || undefined;
    model.updatedDate = !!data['updatedDate'] && new Date(data['updatedDate']) || undefined;
    return model;
  }

  static validate(data: ApiDataResult): Tool {
    const { id, createdDate, updatedDate, ...restData } = data;

    if (!restData['name']) {
      throw new Error('name is required');
    }
    if (!restData['description']) {
      throw new Error('description is required');
    }
    if (!restData['func']) {
      throw new Error('func is required');
    }
    if (restData['schema'] && typeof restData['schema'] === 'string') {
      restData['schema'] = JSON.parse(restData['schema']);
    }

    return this.load(restData)
  }

  toFormData(): FormData {
    debugger;
    const formData = new FormData();
    !!this.id && formData.append('id', this.id);
    !!this.name && formData.append('name', this.name);
    !!this.name && formData.append('code', slugify(this.name));
    !!this.color && formData.append('color', this.color);
    !!this.description && formData.append('description', this.description);
    !!this.func && formData.append('func', this.func);
    !!this.iconFile && formData.append('iconFile', this.iconFile);
    !!this.iconSrc && formData.append('iconSrc', this.iconSrc);
    formData.append('shareable', JSON.stringify(this.shareable));
    !!this.schema && formData.append('schema', JSON.stringify(this.schema));
    return formData;
  }

  toJSON() {
    return {
      id: this.id,
      name: this.name,
      code: this.code,
      color: this.color,
      description: this.description,
      func: this.func,
      icon: this.iconFile,
      iconSrc: this.iconSrc,
      schema: this.schema,
      shareable: this.shareable,
      createdDate: this.createdDate,
      updatedDate: this.updatedDate,
    }
  }
}