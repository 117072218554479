import client from "@/api/client";
import { PaginationProvider, PaginationRequestParams, PaginationResult } from "@/typings";
import { generatePaginationQueryParams } from "@/utils/pagination";
import { useState } from "react";
import { useQuery } from "react-query";

type PaginationProviderParams<T> = {
  queryKey: string;
  endpoint: string;
  parser: (data: Record<string, any>) => T,
  paginationParams: PaginationRequestParams<T>
}

export function usePaginationProvider<T>(params: PaginationProviderParams<T>): PaginationProvider<T> {
  const {
    queryKey,
    endpoint,
    parser,
    paginationParams
  } = params;
  const queryParams = generatePaginationQueryParams(paginationParams);
  return useQuery<PaginationResult<T>, Error>([queryKey, queryParams.currentPage, queryParams.filters], async () => {
    const { data: { data, ...rest } } = await client.get(endpoint, {
      params: queryParams
    });
    return {
      ...rest,
      data: data.map(parser)
    }
  }, {
    keepPreviousData: true,
  });
}

export function usePaginationState(defaultPageSize?: 5 | 10 | 20 | 30, defaultCurrentPage?: number) {
  const [pageSize, _setPageSize] = useState<number>(defaultPageSize || 10);
  const [currentPage, _setCurrentPage] = useState<number>(defaultCurrentPage || 1);

  const handleSetPageSize = (newPageSize: number) => {
    _setPageSize(newPageSize)
    _setCurrentPage(1);
  }
  const handleSetCurrentPage = (newCurrentPage) => {
    _setCurrentPage(newCurrentPage);
  }

  return { pageSize, setPageSize: handleSetPageSize, currentPage, setCurrentPage: handleSetCurrentPage }
}