import { menus, menusKeys } from "./main-menu";

const AppSettings = {
  defaultRequestDelay: 0,
  priorityRequestDelay: 0,
  mainMenu: {
    list: menus,
    keys: menusKeys
  },
  firebaseConfig: {
    apiKey: 'AIzaSyCnt6jSLrLKFgctBxE3B1P4fGL2-zE7KuI',
    authDomain: 'gaiahub.firebaseapp.com',
    projectId: 'gaiahub',
    storageBucket: 'gaiahub.appspot.com',
    messagingSenderId: '681667639099',
    appId: '1:681667639099:web:1d22ba1d77c964dcec291e',
    measurementId: 'G-Y1E4PSFEX8',
  },
  defaultFlags: {
    message_component: "legacy",
    isGuideMenuEnabled: false,
    isMagicHelperEnabled: false,
    isMarketplaceEnabled: false,
  }
}

export default AppSettings