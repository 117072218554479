import { IPagination } from '@/typings';
import { Box, Typography, Select, SelectChangeEvent, MenuItem, Divider, Pagination as MuiPagination, SxProps } from '@mui/material'
import React from 'react'
type PaginationProps = {
  pagination: IPagination,
  showFirstButton?: boolean;
  showLastButton?: boolean;
  onPageSizeChange?: (size: number) => void;
  onPageClick?: (page: number) => void;
  sx?: SxProps
}
export default function Pagination(props: PaginationProps) {
  const { pagination, onPageClick, onPageSizeChange, showFirstButton, showLastButton, sx } = props;

  if (!pagination.currentPage) {
    return null;
  }

  return (
    <Box display='flex' gap={1} p={1} flexGrow={1} justifyContent='flex-end' alignItems='center' sx={sx}>
      <Typography variant='body1'>
        Items per page
      </Typography>
      <Select
        id="datatable-pagesize-selector"
        labelId="datatable-pagesize-selector"
        size='small'
        defaultValue={pagination.pageSize}
        onChange={(evt: SelectChangeEvent<number>) => !!onPageSizeChange && onPageSizeChange(evt.target.value as number)}
      >
        <MenuItem value={5}>5</MenuItem>
        <MenuItem value={10}>10</MenuItem>
        <MenuItem value={20}>20</MenuItem>
        <MenuItem value={30}>30</MenuItem>
      </Select>
      <Divider orientation='vertical' flexItem />
      <MuiPagination
        count={pagination.totalPages}
        page={pagination.currentPage}
        showFirstButton={showFirstButton}
        showLastButton={showLastButton}
        onChange={(e, page) => !!onPageClick && onPageClick(page)}
      />
      <Divider orientation='vertical' flexItem />
      <Typography variant='body1'>
        Total: {pagination.totalItems}
      </Typography>
    </Box>
  )
}
