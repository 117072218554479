import { PaginationRequestParams } from "@/typings";

export function generatePaginationQueryParams(params: PaginationRequestParams<any>) {
  const { currentPage, pageSize, filters, relations } = params
  return {
    pageSize,
    currentPage,
    filters: JSON.stringify(filters),
    relations: JSON.stringify(relations)
  }
}