import { Button } from '@mui/material'
import { IconExternalLink } from '@tabler/icons'
import { t } from 'i18next'
import React from 'react'

type ButtonDocumentationProps = {
  href: string;
  label?: string;
}
export default function ButtonDocumentation(props: ButtonDocumentationProps) {
  const { label = t('common:documentation'), href } = props;
  return (
    <Button
      target='_blank'
      variant='outlined'
      size='small'
      href={href}
      endIcon={<IconExternalLink size={16} />}
    >
      {label}
    </Button>
  )
}
