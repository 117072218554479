import AppSettings from '@/config/main';
import { useMySubscription } from '@/hooks/subscription.hooks';
import { IAppContext } from '@/typings';
import newrelicInstance from '@/utils/newrelic';
import { useUser } from '@clerk/clerk-react';
import { useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';

export const AppContext = React.createContext<IAppContext | null>(null);
type AppContextProviderProps = {}

export const AppContextProvider = ({ children }: PropsWithChildren<AppContextProviderProps>) => {
  const theme = useTheme();
  const { isLoaded } = useUser();
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const matchDownMd = useMediaQuery(theme.breakpoints.down('sm'));

  const { data: userSubscription, isError, isFetching } = useMySubscription({ enabled: isLoaded });
  const [isSidebarOpen, setSidebarOpen] = useState(!isMobile);
  const [selectedPage, setSelectedPage] = useState(null);
  const [smallScreen, setSmallScreen] = useState(matchDownMd);

  const toggleSidebar = useCallback(() => {
    setSidebarOpen(oldValue => !oldValue);
  }, [])

  const context = useMemo(() => ({
    userSubscription,
    isLoadingSubscription: isLoaded && isFetching,
    isSubscriptionLoaded: isLoaded && !isFetching,
    isSubscriptionLoadingError: isError,
    isSidebarOpen,
    setSidebarOpen,
    toggleSidebar,
    selectedPage,
    setSelectedPage,
    smallScreen
  } as IAppContext), [
    userSubscription,
    isError,
    isLoaded,
    isSidebarOpen,
    selectedPage,
    smallScreen
  ]);

  useEffect(() => {
    if (!!userSubscription) {
      newrelicInstance.setUserId(userSubscription.userId);
      newrelicInstance.setCustomAttribute('user_subs_product_id', userSubscription.productId)
      newrelicInstance.setCustomAttribute('user_subs_product_title', userSubscription.product.title)
    }
  }, [userSubscription])

  //find current selectedPage
  useEffect(() => {
    let currentPathname = window.location.pathname;
    if (currentPathname === '/') {
      currentPathname = '/chatflows'
    }
    const currentMenuIndex = AppSettings.mainMenu.keys.findIndex(key => key === currentPathname);
    const currentMenu = AppSettings.mainMenu.list.at(currentMenuIndex);
    setSelectedPage(currentMenu);
  }, [])

  useEffect(() => {
    if (!matchUpMd) {
      setSidebarOpen(false);
    }
  }, [matchUpMd]);
  useEffect(() => {
    setSmallScreen(matchDownMd);
  }, [matchDownMd])

  return (
    <AppContext.Provider value={context}>
      {children}
    </AppContext.Provider>
  )
}

export function useAppContext() {
  if (!AppContext) {
    throw Error("AppContext not provided")
  }
  return useContext(AppContext);
}