import { Card, CardActions, CardContent, CardHeader, Skeleton } from '@mui/material'
import React from 'react'

export default function CardSkeleton() {
  return (
    <Card variant='outlined'>
      <CardHeader
        avatar={<Skeleton variant='circular' width={40} height={40} />}
        title={<Skeleton variant='text' width={100} />}
        subheader={<Skeleton variant='text' width={80} height={20} />}
      />
      <CardContent sx={{ minHeight: 100 }} >
        <Skeleton variant='text' height={20} />
        <Skeleton variant='text' height={20} />
        <Skeleton variant='text' height={20} />
      </CardContent>
      <CardActions disableSpacing>
        <Skeleton variant='text' />
      </CardActions>
    </Card>
  )
}