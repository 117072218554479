import { AppColors } from '@/utils/colors'
import { Avatar, useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import { IconHierarchy, IconTemplate, IconTools } from '@tabler/icons'
import React, { useMemo } from 'react'

type AvatarComponentProps = {
  type: 'template' | 'tool' | 'app',
  name?: string,
}

const avatarComponentIcon = {
  'template': {
    icon: IconTemplate,
    bgcolor: AppColors.primaryDark,
    color: AppColors.primary200
  },
  'tool': {
    icon: IconTools,
    bgcolor: 'grey.main',
    color: 'grey.light'
  },
  'app': {
    icon: IconHierarchy,
    bgcolor: AppColors.successDark,
    color: AppColors.grey200
  }
}

export default function AvatarComponent(props: AvatarComponentProps) {
  const theme = useTheme();
  const { type, name } = props;
  const { icon: Icon, bgcolor, color } = useMemo(() => avatarComponentIcon[type], [type])

  return (
    <Box component='div'>
      <Avatar variant='circular' alt={name} sx={{ color, bgcolor: theme.palette.grey[800] }}>
        <Icon stroke={1.5} size="1.3rem" color={theme.palette.grey[600]} />
      </Avatar>
    </Box>
  )
}
