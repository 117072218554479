import { Avatar, Box, Stack, Typography, useTheme } from '@mui/material';
import React, { memo, useMemo } from 'react';

import userPNG from '@/assets/images/account.png';
import robotPNG from '@/assets/images/robot.png';
import { ChatbotMessage } from '../../../typings';
import FileAnnotations from './FileAnnotations';
import SourceDocuments from './SourceDocuments';
import UsedTools from './UsedTools';

type MessageProps = {

} & ChatbotMessage

function Message(props: MessageProps) {
  const { typography } = useTheme();
  const { message, type, usedTools, fileAnnotations, sourceDocuments } = props;

  const alignContent = useMemo(() => {
    if (type === 'apiMessage')
      return 'flex-start';
    if (type === 'userMessage') {
      return 'flex-end'
    }
    return 'center';
  }, [type]);



  return (
    <Box>
      <Stack direction='row' alignItems='center' justifyContent={alignContent} gap={2}>
        {
          type === 'apiMessage' && (
            <Avatar
              src={robotPNG}
              variant='rounded'
              sx={{
                bgcolor: 'background.default',
                p: 1
              }}
            />
          )
        }
        <Stack direction='column' sx={{ bgcolor: type === 'infoMessage' ? 'grey.main' : 'transparent' }}>
          <Typography variant='body1' flexGrow={1} >
            {message}
          </Typography>
          {!!usedTools && <UsedTools tools={usedTools} />}
          {!!fileAnnotations && <FileAnnotations />}
          {!!sourceDocuments && <SourceDocuments />}
        </Stack>
        {
          type === 'userMessage' && (
            <Avatar
              src={userPNG}
              variant='rounded'
              sx={{
                bgcolor: 'background.default',
                p: 1
              }}
            />
          )
        }
      </Stack>
    </Box>
  )
}

export default memo(Message);
